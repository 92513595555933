import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Checkbox, InputNumber, Modal, Select } from 'antd'
import { getElectionParties } from '../../AadhanApi'
import { Option } from 'antd/es/mentions'
const token = localStorage.getItem('ACCESS_TOKEN')
const AddModal = ({
    initialData,
    type,
    setIsModified,
    setInitialData,
    initialValues,
}) => {
    const [party, setParty] = useState([])
    const [form] = Form.useForm()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onFinish = (values) => {
        setIsModified(false)

        const reqParty = party.filter((e) => e.abbreviation === values.party)
        const temp = [
            ...initialData,
            {
                party: reqParty[0],
                displayName: values?.displayName,
                party_name: values?.party,
                leading: values?.leading,
                won: values?.won,
                total: values?.total,
            },
        ]
        setInitialData(temp)

        form.resetFields('')
        handleOk()
        // handleOk()

        // Handle form submission, e.g., send data to server or update state
    }
    async function getPartyData() {
        const res = await getElectionParties(token)
        setParty(res.data.data)
    }

    useEffect(() => {
        getPartyData()
    }, [])
    const handleValuesChange = (changedValues) => {
      if ('won' in changedValues || 'leading' in changedValues) {
          const won = form.getFieldValue('won') || 0
          const leading = form.getFieldValue('leading') || 0
          form.setFieldsValue({ total: won + leading })
      }
  }
    return (
        <>
            <div onClick={showModal}>{type}</div>
            <Modal
                width={'30%'}
                title="Add"
                open={isModalOpen}
                footer={null}
                closable={true}
                onOk={handleOk}
                onCancel={handleCancel}
            >
                <Form
                    name="myForm"
                    scrollToFirstError
                    labelWrap
                    onValuesChange={handleValuesChange}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    form={form}
                    onFinish={onFinish}
                    style={{ padding: '2rem' }}
                >
                    <Form.Item
                        name="party"
                        label="Party"
                        rules={[
                            {
                                required: true,
                                message: 'Please Select the party name!',
                            },
                        ]}
                    >
                        <Select
                            showSearch
                            placeholder="Select Candidate"
                            style={{ width: 200, margin: '0 2rem 0 0' }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                            }
                        >
                            {party.map((e) => (
                                <Option value={e.abbreviation} key={e.party_id}>
                                    {e.abbreviation}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="displayName"
                        label="Display Name"
                        rules={[
                            {
                                required: true,
                                message: 'Please input the display name!',
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="leading"
                        label="Leading"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please input the number of leading votes!',
                            },
                        ]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="won"
                        label="Won"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please input the number of votes won!',
                            },
                        ]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }}  />
                    </Form.Item>
                    <Form.Item
                        name="total"
                        label="Total"
                        rules={[
                            {
                                required: true,
                                message:
                                    'Please input the total number of Votes!',
                            },
                        ]}
                    >
                        <InputNumber min={0} style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ float: 'right' }}
                            type="primary"
                            htmlType="submit"
                        >
                            {type === 'Add' ? 'Add' : 'save'}
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default AddModal
