import React, { useEffect, useState } from 'react';
import { Table, Button, Space } from 'antd';
import { deleteParties, getElectionStates } from '../../AadhanApi';


import dayjs from 'dayjs'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ElectionTable from '../ElectionCommonTable/ElectionTable';
import AddMediaForm from './AddMediaForm';
import EditMediaForm from './EditMediaForm';


const ManageMediaTable = ({dataSource,handleDelete,token,getMedia,searchTerm,handleChange}) => {
    const[initialData,setInitialData] = useState({});
    const[state,setState] = useState([]);
    const columns = [
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        
        },
        {
          title: 'Logo',
          dataIndex: 'logo',
          key: 'logo',
          render: (text) => <img src={text} alt="Flag" style={{ width: 60, height: 60,borderRadius:"5px" }} />,
        },
        {
          title: 'Display Name',
          dataIndex: 'display_name',
          key: 'display_name',
        },
        {
         title:"State",
         dataIndex:"state_name",
         key:"state_name",
         render: (text,record) => <spna>{record.state['state_name']}</spna>,
        },
       
        {
          title: 'Edit / Delete',
          key: 'edit',
          render: (text, record) => (
            <Space>
              <Button onClick={()=>handleEdit(record)} type='danger' ><EditMediaForm initialData={record} getMedia={getMedia} getElectionStates={getMedia} electionStates={state} token={token}/></Button>
              <Button type="danger" onClick={() => handleDelete(record.media_id)}><DeleteOutlined/></Button>
            </Space>
          ),
        },
      ];
      function handleEdit(record){
        setInitialData(record)
      }
      async function getState(){
        const data = await getElectionStates(token)
        setState(data.data.data)
      }
      useEffect(()=>{
        getState()
      },[])
     
      
     
  return(
     <div> 
      <div style={{display:"flex",justifyContent:"end",padding:".4rem"}}> 
      <input
        type="text"
        
        placeholder="Search Media..."
        value={searchTerm}
        onChange={handleChange}
        style={{border:"solid lightgray 1px",borderRadius:"5px",outline:"none",marginRight:"2rem"}}
      />
      <AddMediaForm token={token} getMedia={getMedia}  electionStates={state} />
      </div> 
      <ElectionTable dataSource={dataSource} electionStates={state} columns={columns} />
     </div>
  );
};

export default ManageMediaTable;
