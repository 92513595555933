import React, { useEffect, useState } from 'react'
import { getMedia,deleteMedia } from '../../AadhanApi'
import ManageMediaTable from './ManageMediaTable';



const ManageMedia = () => {
  const [data,setData] =useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dataSource,setDataSource] = useState([]);
  const token = localStorage.getItem('ACCESS_TOKEN')
  async function getMediaSources(){
    const data = await getMedia(token)

    // data.data.data['state_name']=data.data.data['state']?.state_name;
    setDataSource(data.data.data)
    setData(data.data.data)
   

  }
  const handleDelete = async(id) => {
    // Implement delete logic here
    const res = await deleteMedia(id,token)
    getMediaSources()
    
  };  
  useEffect(()=>{
   getMediaSources()
  },[])
  const handleChange = (event) => {
   
    if(!event.target.value){
      setDataSource(data)
      setSearchTerm('')
      return
    }
    setSearchTerm(event.target.value);
    const filtered = data.filter((item) =>
      item?.name?.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDataSource(filtered);
    
  };
  return (
    <div>
      <h2 style={{ padding: '0 1rem .5rem 1rem',borderBottom:"solid 1px lightGray" }}>Manage Media Sources</h2>
      
      <ManageMediaTable dataSource={dataSource} searchTerm={searchTerm} handleChange={handleChange} handleDelete={handleDelete} getMedia={getMediaSources} token={token}/>
    </div>
  )
}

export default ManageMedia
