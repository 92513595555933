import React, { useState, useEffect } from 'react'
import {
    Button,
    DatePicker,
    Form,
    Input,
    Modal,
    Select,
    InputNumber,
} from 'antd'

import {
    getStateParties,
    getElectionStates,
    createPulse,
    updatePulse,
} from '../../AadhanApi'
import dayjs from 'dayjs'
import { EditOutlined } from '@ant-design/icons'
import { electionType } from './AddPulseConstant'
import FormItem from 'antd/es/form/FormItem'
import moment from 'moment'

export const EditPulseForm = ({
    initialData,
    party,
    pulse,
    token,
    partyinfo,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const accessToken = localStorage.getItem('ACCESS_TOKEN')
    const [form] = Form.useForm()

    const [parties, setParties] = useState([])

    const [stateId, setStateId] = useState('')
    const [locationsData, setLocationsData] = useState([])

    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    function handleStateChange(e) {
        setStateId(e)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const getStatesList = async () => {
        const response = await getElectionStates(accessToken).then((res) => {
            if (res?.data.data) {
                const data = [
                    { state_id: 37, state_name: 'India' },
                    ...res.data.data,
                ]
                setLocationsData(data)
            }
        })
    }
    async function getParties(stateId) {
        if(stateId!==''){
            const data = await getStateParties(token, stateId)
        const partyNames = data?.data?.map((p) => ({
            name: p?.name,
            id: p?.id,
        }))
        setParties(partyNames)
        }
    }

    useEffect(() => {
        // initialData['name'] = initialData['state'].namey

        initialData.Event_Date = moment(initialData.Event_Date)

        form.setFieldsValue(initialData)
    }, [initialData])
    useEffect(() => {
        getStatesList()
    }, [])
    useEffect(() => {
        getParties(initialData.stateId)
    }, [initialData])

    function createRequest(values) {
        const partiesInfo = {}
        const formattedDate = dayjs(values.event_date).unix()
        for (const key in values) {
            if (
                key !== 'stateId' &&
                key !== 'Event_Title' &&
                key !== 'Event_Date' &&
                key !== 'Total_votes' &&
                key !== 'Election_Type'
            ) {
                partiesInfo[key] = {
                    partywise_votes: values[key],
                }
            }
        }

        // Creating the desired output object
        const output = {
            stateId: values.stateId,
            Event_Title: values.Event_Title,
            Event_date: formattedDate,
            Total_votes: values.Total_votes,
            Election_Type: values.Election_Type,
            Parties: partiesInfo,
        }
        return output
    }
    async function handleFormSubmit(values) {
        const req = createRequest(values)
        const res = await updatePulse(req, token)
        form.resetFields()
        pulse()
        handleOk()
    }
    return (
        <>
            <div onClick={showModal}>
                <EditOutlined />
            </div>
            <Modal
                title={<h5>Edit Pulse</h5>}
                closable={false}
                footer={false}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
            >
                <hr></hr>
                <Form
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={handleFormSubmit}
                    form={form}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please select state',
                            },
                        ]}
                        name="stateId"
                        label="Select State"
                    >
                        <Select
                            onChange={(e) => {
                                handleStateChange(e)
                            }}
                            // defaultValue={'Andhra Pradesh'}
                            style={{ width: '10rem', height: '2rem' }}
                        >
                            {locationsData.map((e) => {
                                return (
                                    <Select.Option
                                        value={e.state_id}
                                        key={e.state_id}
                                    >
                                        {e.state_name}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter event name',
                            },
                        ]}
                        name="Event_Title"
                        label="Event Name"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item label="Event Date" name="Event_Date">
                        <DatePicker
                            showTime
                            format="DD/MM/YYYY h:mm A"
                        />
                    </Form.Item>
                    <FormItem name="Total_votes" label="Total Seats">
                        <InputNumber style={{ width: 250 }} placeholder="23" />
                    </FormItem>

                    <Form.Item
                        name="Election_Type"
                        label="Election Type"
                        rules={[{ required: true }]}
                    >
                        <Select style={{ width: 200 }}>
                            {electionType.map((option) => (
                                <Select.Option
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {parties.map((e) => (
                        <Form.Item label={e.name} name={e.name} key={e.name}>
                            <InputNumber
                                style={{ width: 250 }}
                                placeholder="23"
                            />
                        </Form.Item>
                    ))}
                    <Form.Item>
                        <Button
                            style={{ display: 'block', margin: '0 auto' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default EditPulseForm
