import React, { useEffect, useState } from 'react';
import { Form, Input, Button, Checkbox, InputNumber ,Modal, Select} from 'antd';
import { getElectionParties } from '../../AadhanApi';
import { Option } from 'antd/es/mentions';
const token = localStorage.getItem('ACCESS_TOKEN')
const EditModal = ({initialData,type,setIsModified,setInitialData,initialValues,index}) => {
  const [party,setParty] = useState([]);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onFinish = (values) => {
    setIsModified(false)
    
    const temp = initialData.map((e,ind)=>{
        if(ind===index){
            return {...e,seats:values.projectedSeats,party_name:values.party_name,displayName:values.displayName}
        }
        else{
            return e
        }
    })
    setInitialData(temp)
   form.resetFields('')
    handleOk()
  };
  async function getPartyData(){
    const res = await getElectionParties(token)
    setParty(res.data.data)
  }
  const regex = /^(([\d]+)|([\d]+\+)|([\d]+-[\d]+))$/;
  useEffect(()=>{
    getPartyData()
  },[])
  useEffect(() => {
    form.setFieldsValue(initialValues)
}, [initialValues, form])
  return (
    <>
      <div onClick={showModal}>
        {type}
      </div>
      <Modal width={"30%"} open={isModalOpen} title="Edit" footer={null} closable={true} onOk={handleOk} onCancel={handleCancel}>
      <Form
      name="myForm"
      scrollToFirstError
      labelWrap
      labelCol={{ span: 8 }}
      wrapperCol={{ span: 18 }}
      form={form}
      onFinish={onFinish}
      style={{padding:"2rem"}}
    >
      <Form.Item
        name="party_name"
        label="Party"
        rules={[{ required: true, message: 'Please Select the party name!' }]}
      >
       <Select disabled={true}>
        {party.map(e=><Option value={e.abbreviation} key={e.party_id}>{e.abbreviation}</Option>)}
       </Select>
      </Form.Item>
      <Form.Item
        name="displayName"
        label="Display Name"
        rules={[{ required: true, message: 'Please input the display name!' }]}
      >
        <Input disabled={true} />
      </Form.Item>
      <Form.Item
        label="Projected Seats"
        name="projectedSeats"
        rules={[
          { required: true, message: 'Please input projected seats!' },
          {
            pattern: regex,
            message: 'Projected seats must match the pattern: 5, 5+, 5-10',
          },
        ]}
      >
        <Input type="text" placeholder="Enter projected seats" />
      </Form.Item>
      <Form.Item>
        <Button style={{float:"right"}} type="primary" htmlType="submit">
          {type === 'Add' ?'Add':'save'}
        </Button>
      </Form.Item>
    </Form>
      </Modal>
    </>
  );
};
export default EditModal;