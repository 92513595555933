import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { getElectionStates, getPulsePollsData } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import ManagePulsePollsTable from './ManagePulsePollsTable'
import { electionType } from './AddPulsePollsConstant'
const ManagePulsePolls = () => {
    const [dataSource, setDataSource] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const { Option } = Select
    const [locationsData, setLocationsData] = useState([])
    const [selectedSateId, setSelectedSateId] = useState(37)
    const [electiontype, setElectionType] = useState('loksabha_elections')
    const token = localStorage.getItem('ACCESS_TOKEN')
    useEffect(() => {
        getStatesList()
        getPulsePolls()
    }, [selectedSateId, electiontype])

    const getStatesList = async () => {
        const response = await getElectionStates(token)
        const data = [
            { state_id: 37, state_name: 'India' },
            ...response.data.data,
        ]

        setLocationsData(data)
    }
    const getPulsePolls = async () => {
        setDataLoading(true)
        if (electionType && selectedSateId) {
            const response = await getPulsePollsData(
                selectedSateId,
                electiontype
            )
            setDataSource(response.data)
            setDataLoading(false)
        }
    }
    const handleStateChange = async (event) => {
        setSelectedSateId(event)
    }

    return (
        <div>
            <h2
                style={{
                    padding: '0 1rem .5rem 1rem',
                    borderBottom: 'solid 1px lightGray',
                }}
            >
                Manage Pulse Polls
            </h2>
            <Select
                placeholder="Select State"
                onChange={(e) => handleStateChange(e)}
                defaultValue={37}
                style={{ width: 150, marginRight: '2rem' }}
            >
                {locationsData.map((option, index) => (
                    <Option key={option.state_id} value={option.state_id}>
                        {option.state_name}
                    </Option>
                ))}
            </Select>
            <Select
                placeholder="Select Election Type"
                defaultValue={'loksabha_elections'}
                style={{ width: 150, marginRight: '2rem' }}
                onChange={(value) => setElectionType(value)}
            >
                {electionType.map((optionValue) => (
                    <Option key={optionValue.value} value={optionValue.value}>
                        {optionValue.label}
                    </Option>
                ))}
            </Select>

            <ManagePulsePollsTable
                dataSource={dataSource}
                dataloading={dataLoading}
                stateId={selectedSateId}
                token={token}
                electionType={electionType}
            />
        </div>
    )
}

export default ManagePulsePolls
