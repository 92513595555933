import React, { useEffect, useState } from 'react'
import { Modal, Button, Form, Input, Select } from 'antd'
import { createConstituencyResult, getCandidates, getElectionConstituencies, getElectionParties, updateConstituencyResult } from '../../AadhanApi'
import CommonTable from '../CommonTable/CommonTable'
import { electionType } from '../ManagePulse/AddPulseConstant'

const { Option } = Select

const AddConstituencyResult = ({
    allState,
    selectedState,
    setSelectedState,
    token,
    type,
    data,
    getResults,
    action,
    record,
    setType
}) => {
    const [selctedType, setSelectedType] = useState(type)
    const currentYear = new Date().getFullYear()
    const [year, setYear] = useState(currentYear)
    const years = Array.from({ length: 6 }, (_, index) => currentYear + index)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [form] = Form.useForm()
    const [allConstituency, setAllConstituency] = useState([])
    const [seletedConstituency, setSelectedConstituency] = useState('')
    const [tableData, setTableData] = useState([
       
    ])
    const[resultId,setResultId] = useState(undefined)
    const [allCandidate, setAllCandidate] = useState([])
    const [party, setParty] = useState([])
    const [edited, setEdited] = useState(true)
    const [isAddButtonDisabled, setIsAddButtonDisabled] = useState()
    const [initialData,setInitialData] = useState([])
    
    
    function handleChange(record, index, option) {
       
        setEdited(false)
        const temp = tableData.map((e,ind) => {
            if (ind === index) {

                if (option === 'party') {
                    const partyres = party.filter((e)=>e.party_id===record)
                    return { ...e, partyName: partyres?.[0]?.abbreviation,partyObj:partyres[0] }

                } else {
                    const candidateres =allCandidate.filter((e)=>e.candidate_id===record)
                    return { ...e, candidateName: candidateres[0]?.full_name ,candidateObj:candidateres[0] }
                }
            } else {
                return e
            }
        })
        setTableData(temp)
        // Update the add button state based on the first row completion
        setIsAddButtonDisabled(false)
    }
    async function handleSave() {

        if (seletedConstituency === undefined) {
            alert('Please select a constituency to Continue.');
            return
        }
        const stateres = allState.filter((e)=>e.state_id===selectedState);
        const consres = allConstituency.filter((e)=>e.constituency_no===seletedConstituency)
        const req= {
            "election_type": selctedType,
            "state": {
              "state_id": stateres[0]?.state_id,
              "state_cd": stateres[0]?.state_cd,
              "state_name": stateres[0]?.state_name
            },
            "constituency": {
              "constituency_no": consres[0]?.constituency_no,
              "constituency_name": consres[0]?.constituency_name,
              "district_cd":consres[0]?.district_cd ? consres[0]?.district_cd :null,
            },
            "election_year": year,
            "candidates":tableData.map((e)=>{
                return {
                "candidate_id": e?.candidateObj?.candidate_id,
                "candidate_name": e?.candidateObj?.full_name,
                "party": {
                  "party_id":e?.partyObj?.party_id,
                  "name": e?.partyObj?.name,
                  "abbreviation": e?.partyObj?.abbreviation,
                  "party_flag_img": e?.partyObj?.party_flag_img
                },
               
                }
            })
            
          }
          if(resultId){
            const res = await updateConstituencyResult(resultId,req,token)
          }
          else{

              const res = await createConstituencyResult(req,token)
          }
          getResults()
      
        setEdited(true)
      
    }

   
   useEffect(() => {
    
    const filterAndTransformData = () => {
        if (!data || !selectedState || !seletedConstituency || !year || !type) {
            return [{ partyName: '', candidateName: '' }];
        }

        const filteredData = data.find(e =>
            e.state?.state_id === selectedState &&
            e.constituency?.constituency_no === seletedConstituency &&
            e.election_year === year &&
            e.election_type === type
        );

        if (!filteredData || !filteredData.candidates) {
            setResultId(undefined)
            return [{ partyName: '', candidateName: '' }];
        }
        setResultId(filteredData?.result_id)
        const transformedData = filteredData.candidates.map(candidate => ({
            ...candidate,
            partyName: candidate?.party?.party_id || '',
            partyObj: candidate.party,
            candidateObj: {
                full_name: candidate.candidate_name,
                candidate_id: candidate.candidate_id
            },
            candidateName: candidate.candidate_id
        }));
        return transformedData.length ? transformedData : [{ partyName: '', candidateName: '' }];
    };

    const updatedTableData = filterAndTransformData();
    setTableData(updatedTableData);
    setIsAddButtonDisabled(updatedTableData?.[0].partyName==='' | updatedTableData?.[0].candidateName==='' ? true : false);

}, [data, selectedState, seletedConstituency, year, type]);




    const columns = [
        {
            title: 'Party Symbol',
            key: 'party',
            render: (_, record, index) => (
                <Select
                    showSearch
                    onChange={(e) => handleChange(e, index, "party")}
                    value={record.partyName || undefined}
                    placeholder="Select Party"
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    optionFilterProp="label"
                    filterOption={(input, option) =>
                        option.label.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {party.map((e) => (
                        <Option value={e.party_id} key={e.party_id} label={e.abbreviation}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <img
                                    style={{ width: 22, height: 22, borderRadius: '50%', marginRight: '0.5rem' }}
                                    src={e.party_flag_img}
                                    alt={`${e.abbreviation} flag`}
                                />
                                {e.abbreviation}
                            </div>
                        </Option>
                    ))}
                </Select>
            ),
        },
        {
            title: 'Candidate',
            key: 'candidate',
            render: (_, record, index) => (
                <Select
                    showSearch
                    onChange={(e) => handleChange(e,index,'candidate')}
                    value={record.candidateName || undefined}
                    placeholder="Select Candidate"
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {allCandidate.map((e) => (
                        <Option value={e.candidate_id} key={e.candidate_id}>
                            {e.full_name}
                        </Option>
                    ))}
                </Select>
            ),
        },
         {
            title: 'Actions',
            key: 'actions',
            render: (_, record,index) => (
                <Button type='primary' ghost onClick={()=>{
                    setTableData(tableData.filter((e,ind)=>ind!==index))
                    setEdited(false)
                }}>Delete</Button>
            )
        } 
    ]

    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
        form.resetFields()
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    async function getConstituencyFunc() {
        const data = await getElectionConstituencies(selctedType, selectedState, token)
        setAllConstituency(data.data.data)
        if(action==='edit'){
            // setSelectedState(record?.state?.state_id)
            setYear(record?.election_year)
            setSelectedConstituency(record?.constituency?.constituency_no)
            
        }
        else{
            if (selctedType === 'loksabha_elctions') {
                setSelectedConstituency(data.data.data[0].constituency_no)
            } else {
                setSelectedConstituency(data.data.data[0].constituency_no)
            }
        }
        
       
    }
    async function getPartyCandidates(){
        const p = await getElectionParties(token)
        setParty(p.data.data)
        const c = await getCandidates(token)
        setAllCandidate(c.data.data)
    }
    useEffect(()=>{
       getPartyCandidates()
    },[])

    useEffect(() => {
    
        setAllConstituency([])  
      
        getConstituencyFunc()
       
       
    }, [selectedState,selctedType,record])

    useEffect(() => {
        setSelectedType(type)
    }, [type])

    function handleAdd() {
        setTableData([...tableData, { party: '', candidate: '' }])
        setIsAddButtonDisabled(true)  // Disable add button after adding a row
    }
    return (
        <>
            <Button type='primary' style={{display:"block",margin:"0 0 .5rem auto"}}  onClick={showModal}>
                {action === 'edit' ? "Edit" :"Map Candidates"}
            </Button>
            <Modal
                width={'80%'}
                style={{ left: '9%' }}
                title="Map Candidates"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                footer={null}
            >
                <hr />
                <Select
                    showSearch
                    onChange={(e) =>{ setSelectedType(e);setType(e)}}
                    value={selctedType}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    placeholder="Select Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {/* <Option value="loksabha_elections" key="loksabha_elections">
                        Loksabha
                    </Option> */}
                    <Option value="assembly_elections" key="assembly_elections">
                        Assembly
                    </Option>
                </Select>
                <Select
                    showSearch
                    onChange={(e) => setSelectedState(e)}
                    value={selectedState}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    placeholder="Select State"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {allState.map((e) => (
                        <Option value={e.state_id} key={e.state_id}>
                            {e.state_name}
                        </Option>
                    ))}
                </Select>
                <Select
                    onChange={(e) => setYear(e)}
                    value={year}
                    style={{ width: 120, margin: '0 2rem 0 0' }}
                    placeholder="Select Year"
                >
                    {years.map((year) => (
                        <Option key={year} value={year}>
                            {year}
                        </Option>
                    ))}
                </Select>
                <Select
                    showSearch
                    onChange={(e) => setSelectedConstituency(e)}
                    value={seletedConstituency}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    placeholder="Select Constituency"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children.toLowerCase().includes(input.toLowerCase())
                    }
                >
                    {allConstituency.map((e) => (
                        <Option key={e.constituency_no} value={e.constituency_no}>
                            {e.constituency_name}
                        </Option>
                    ))}
                </Select>
                <div style={{ display: 'flex' }}>
                    <div style={{ width: '40rem', margin: '1rem 0' }}>
                        <CommonTable data={tableData} columns={columns} />
                    </div>
                    <Button
                        onClick={handleAdd}
                        type="primary"
                        style={{ margin: 'auto 0 4rem 2rem' }}
                        disabled={isAddButtonDisabled}
                    >
                        Add
                    </Button>
                </div>
                {edited && "every thing is upto date" }
                <Button onClick={handleSave} disabled={edited} style={{ display: "block", margin: "0 auto" }} type="primary">
                    {action==='edit' ? 'update' : 'save'}
                </Button>
            </Modal>
        </>
    )
}

export default AddConstituencyResult
