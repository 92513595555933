import React, { useState } from 'react';
import { Card } from 'antd';
import {ArrowRightOutlined  ,MoreOutlined} from '@ant-design/icons'

const { Meta } = Card;

const MyCard = ({setTitle,title,start,end,result,phases,setIsClicked,id,setElection_id}) => {
  
  const[isClicked_,setIsClicked_]=useState(false)
  return (
    <Card

      hoverable
      style={{ width: "22%",backgroundColor:"lightGray" }}
      
    >
     <div style={{display:"flex",justifyContent:"space-between"}}>
      <h6>{title}</h6>
      <span style={{fontSize:"1rem"}}><MoreOutlined/></span>
      </div> 
      
      <div style={{ marginTop: '10px' }}>
        <p>No Of Phases : {phases}</p>
        <p>Start Date : {start}</p>
        <p>End Date : {end}</p>
        <p>Results : {result}</p>
        <div onClick={()=>{setIsClicked(true);setElection_id(id);setTitle(title)}}  style={{backgroundColor:"blue",color:"white",padding:".3rem .6rem",borderRadius:"5px",cursor:"pointer",display:"block",marginLeft:"80%"}} ><ArrowRightOutlined/></div>
      </div>
    </Card>
  );
};

export default MyCard;
