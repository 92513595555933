import { DatePicker, Form, Select, Input, Button } from 'antd'
import { Option } from 'antd/es/mentions'
import React, { useEffect, useState } from 'react'
import PhasesTable from './PhasesTable'
import { useDispatch, useSelector } from 'react-redux'
import {
    addFormData,
    addInitialPhase,
    onChangeState,
    resetFormData,
    resetState,
    resetconstituencies,
} from '../../store/slice/ElectionSlice'
import dayjs from 'dayjs'
import { createElection, getElectionStates } from '../../AadhanApi'

const CreateElectionForm = () => {
    const [selectedElectionType, setSelectedElectionType] =
        useState('assembly_elections')
    const [noPhases, setNoPhases] = useState(0)
    const dispatch = useDispatch()
    const formData = useSelector((state) => state.elections.formData)
    
    const states = useSelector((state) => state.elections.states)
    const [electionState, setElectionStates] = useState([])
    const selectedState = useSelector((state) => state.elections.selectedState)


    const constituencies = useSelector(
        (state) => state.elections.constituencies
    )
    const phases = useSelector((state) => state.elections.initialPhase)
    const [temp, setTemp] = useState([])
    const token = localStorage.getItem('ACCESS_TOKEN')
    const [form] = Form.useForm()
    const [stateObj,setStateObj] =useState({});
    async function getStates() {
        const data = await getElectionStates(token)
        setElectionStates(data.data.data)

    }
    useEffect(() => {
        getStates()
    }, [])

    function one(phase, date) {
        let one_ = {}
        one_['phase_no'] = phase
        one_['election_date'] = date
        if(formData.electionType!=='assembly_elections' && formData.electionType!=='assembly_bye_elections'){

            one_['states'] = []
        }
        setStateObj(states['phase_no' + phase][0])
        
        states['phase_no' + phase]?.forEach((e) => {
            const stateId = e.state_id

            let y = undefined
            if (
                constituencies['phase_no' + phase] &&
                constituencies['phase_no' + phase][stateId]
            ) {
                y = constituencies['phase_no' + phase][stateId].filter(
                    (c) => c.direction === 'right'
                )
                y = y.map((obj) => {
                    const newObj = {}
                    const keys = Object.keys(obj)
                    for (let i = 0; i < Math.min(2, keys.length); i++) {
                        newObj[keys[i]] = obj[keys[i]]
                    }
                    return newObj
                })
            }

            if (y && y.length) {
                if(formData.electionType==='assembly_elections' || formData.electionType==='assembly_bye_elections'){
                    one_['constituencies'] = y
                }
                else{
                one_['states'].push({

                    state_id: e.state_id,
                    state_cd: e.state_cd,
                    state_name: e.state_name,
                    constituencies: y,
                })
            }
            }
        })

        return one_ // Return the structured object
    }

    async function createElectionfunc() {
        const temp =await phases.map((e) =>
            one(
                parseInt(e.phase.split(' ')[1]),
                dayjs(e.date).format('YYYY-MM-DD')
            )
        )
       
        let req
        if (formData.electionType === 'assembly_elections' || formData.electionType==='assembly_bye_elections') {
            
            req = {
                election_type: formData.electionType,
                start_date: dayjs(formData.startDate).format('YYYY-MM-DD'),
                end_date: dayjs(formData.endDate).format('YYYY-MM-DD'),
                counting_date: dayjs(formData.countingDate).format(
                    'YYYY-MM-DD'
                ),
                'election_data': {
                    phases: temp,
                    state:{
                        state_id:stateObj['state_id'],
                        state_cd:stateObj['state_cd'],
                        state_name:stateObj['state_name'],
                    },
                },
               
            }
        } else {
             req = {
                election_type: formData.electionType,
                start_date: dayjs(formData.startDate).format('YYYY-MM-DD'),
                end_date: dayjs(formData.endDate).format('YYYY-MM-DD'),
                counting_date: dayjs(formData.countingDate).format(
                    'YYYY-MM-DD'
                ),
                election_data: {
                    phases:temp,
                },
            }
        }

        
        const data = await createElection(req, token)
        
    }

    return (
        <div style={{ margin: '1rem' }}>
            <Form
                initialValues={formData}
                onFinish={createElectionfunc}
                form={form}
            >
                <div style={{ display: 'flex' }}>
                    <Form.Item
                        name="electionType"
                        label="Choose type"
                        rules={[
                            {
                                required: true,
                                message: 'Please select Election type',
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => {
                                setSelectedElectionType(e)
                                dispatch(resetFormData(e))
                                dispatch(resetState())
                                dispatch(resetconstituencies())
                                form.setFieldValue('noOfPhases', '')
                                dispatch(
                                    addFormData({
                                        key: 'electionType',
                                        data: e,
                                    })
                                )
                            }}
                            style={{ width: 200 }}
                        >
                            <Option value="assembly_elections">
                                Assembly Elections
                            </Option>
                            <Option value="loksabha_elections">
                                Lok Sabha Elections
                            </Option>
                            <Option value="assembly_bye_elections">
                                Assembly By Elections
                            </Option>

                            <Option value="loksabha_bye_elections">
                                Lok Sabha By Elections
                            </Option>
                        </Select>
                    </Form.Item>
                </div>

                <Form.Item
                    name="startDate"
                    label="Select Start Date"
                    rules={[
                        {
                            required: true,
                            message: 'Please select start date',
                        },
                    ]}
                >
                    <DatePicker
                        format={{
                            format: 'YYYY-MM-DD',
                            type: 'mask',
                        }}
                        onChange={(date, dateString) =>
                            dispatch(
                                addFormData({ key: 'startDate', data: date })
                            )
                        }
                    />
                </Form.Item>
                <Form.Item
                    name="endDate"
                    label="Select End Date"
                    rules={[
                        {
                            required: true,
                            message: 'Please select end date',
                        },
                    ]}
                >
                    <DatePicker
                        format={{
                            format: 'YYYY-MM-DD',
                            type: 'mask',
                        }}
                        onChange={(date, dateString) =>
                            dispatch(
                                addFormData({ key: 'endDate', data: date })
                            )
                        }
                    />
                </Form.Item>
                {formData.electionType === 'assembly_elections' && (
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please select state',
                            },
                        ]}
                        initialValue={selectedState}
                        name="state_name"
                        label="Select State"
                        style={{ width: 270 }}
                    >
                        <Select onChange={(e) => dispatch(onChangeState(e))}>
                            {electionState?.map((e) => (
                                <Option value={e.state_name} key={e.state_id}>
                                    {e.state_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                )}
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please select Counting Date',
                        },
                    ]}
                    name="countingDate"
                    label="Counting Date"
                >
                    <DatePicker
                        format={{
                            format: 'YYYY-MM-DD',
                            type: 'mask',
                        }}
                        onChange={(date, dateString) =>
                            dispatch(
                                addFormData({ key: 'countingDate', data: date })
                            )
                        }
                    />
                </Form.Item>

                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Please select no of phases',
                        },
                    ]}
                    label="No Of Phases"
                    name="noOfPhases"
                >
                    <Select
                        onChange={(e) => {
                            setNoPhases(e)
                            const dataSource = Array.from(
                                { length: e },
                                (_, index) => ({
                                    key: String(index + 1),
                                    btn: 'Map Now',
                                })
                            )

                            dispatch(resetState())
                            dispatch(resetconstituencies())
                            dispatch(addInitialPhase(dataSource))
                            dispatch(
                                addFormData({ key: 'noOfPhases', data: e })
                            )
                        }}
                        style={{ width: 200 }}
                        placeholder="select no of phases"
                    >
                        <Option value={1}>1</Option>
                        <Option value={2}>2</Option>
                        <Option value={3}>3</Option>
                        <Option value={4}>4</Option>
                        <Option value={5}>5</Option>
                        <Option value={6}>6</Option>
                        <Option value={7}>7</Option>
                    </Select>
                </Form.Item>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                    }}
                >
                    <Form.Item>
                        {formData.noOfPhases !== '' && (
                            <div style={{ width: '40rem' }}>
                                <PhasesTable
                                    electionType={selectedElectionType}
                                />
                            </div>
                        )}
                    </Form.Item>
                    <Form.Item>
                        {formData.noOfPhases !== '' && (
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{
                                    padding: '0 1.5rem 0 1.5rem',
                                }}
                            >
                                Create Election
                            </Button>
                        )}
                    </Form.Item>
                </div>
            </Form>
        </div>
    )
}

export default CreateElectionForm
