import React, { useEffect, useState } from 'react'
import CommonTable from '../CommonTable/CommonTable'
import { Button } from 'antd';
import { SwapOutlined } from '@ant-design/icons';
import AddConstituencyResult from './AddConstituencyResult';
import { deleteConstituencyResult, getConstituencyResult, getElectionStates } from '../../AadhanApi';

const ConstituencyResult = () => {
  const [type,setType] = useState('assembly_elections');
  const [selectedState,setSelectedState] = useState(1)
  const token = localStorage.getItem('ACCESS_TOKEN');
  const [allState,setAllState] = useState([])
  const [result,setResult] = useState([]);
  
  function handleChange(){
    if(type==='loksabha_elections'){
      setType('assembly_elections')

    }
    else{
      setType('loksabha_elections')
    }
  }

  async function getData(){
    const data = await getElectionStates(token)
     setAllState([{labe:'Andhra Pradesh',state_name:'Andhra Pradesh',state_id:1,state_cd:"S01"}])
     setSelectedState(data.data.data[0].state_id)


  }
  useEffect(()=>{
    getData()
  },[])
  async function getResults(){
    const res = await getConstituencyResult(token,type)
    setResult(res.data.data)
  }
  useEffect(()=>{
      getResults()
  },[type])

  
  
  const handleDelete = async(record) => {
    const data = await deleteConstituencyResult(record,token)
   
    getResults()
  };
  const columns = [
    {
      title:<div>{type}
       {/* <span onClick={()=>handleChange()} style={{margin:"0 1rem",cursor:"pointer"}}><SwapOutlined /></span> */}
       </div>,
      dataIndex: 'election_type',
      key: 'election_type',
    },
    {
      title: 'State',
      key: 'state',
      render: (_, record) => (
        <span>
         {record?.state?.state_name}
        </span>)
    },
    {
      title: 'Constituency',
      key: 'constituency',
      render: (_, record) => (
        <span>
         {record?.constituency?.constituency_name}
        </span>)
    },
    {
      title: 'Year',
      dataIndex: 'election_year',
      key: 'year',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <span style={{display:"flex",justifyBetween:"spaceAround"}}>
          <AddConstituencyResult data={result} action={'edit'} record={record} getResults={getResults} setType={setType} selectedState={record?.state?.state_id} token={token} setSelectedState={setSelectedState} type={type} allState={allState}/>  
          <Button type="link" onClick={() => handleDelete(record.result_id)}>Delete</Button>
        </span>
      ),
    },
  ];
  return (
    <div style={{padding:"0 .5rem"}}>
     <h3>Manage Candidate Mapping</h3>
     <hr></hr> 
     <AddConstituencyResult data={result} getResults={getResults} action={'add'} setType={setType} selectedState={selectedState} token={token} setSelectedState={setSelectedState} type={type} allState={allState}/>  
     <CommonTable dataLoading={false} data={result} columns={columns}/>
    </div>
  )
}

export default ConstituencyResult
