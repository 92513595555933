import React, { useState } from 'react';
import { Button, Modal } from 'antd';
import AddExitPollsTable from './AddExitPollsTable';
const AddExitPolls = ({title,source,data,getFetchData,type}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div  onClick={showModal}>
       {title === 'add' ?'Add Exit Polls':'Edit'}
      </div>
      <Modal width={'80%'} style={{left:"9%"}} footer={null} title={title === 'add' ?'Add Exit Polls':'Edit / Delete Exit Polls'} closable={true} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <AddExitPollsTable source={source} type={type} getFetchData={getFetchData} title={title} data={data}/>
      </Modal>
    </>
  );
};
export default AddExitPolls;