import React, { useEffect, useState } from 'react'
import { deleteParties, getElectionParties } from '../../AadhanApi'
import ManagePartiesTable from './ManagePartiesTable'

const ManageParties = () => {
  const [dataSource,setDataSource] = useState([]);
  const [data,setData] =useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const token = localStorage.getItem('ACCESS_TOKEN')
  async function getParties(){
    const data = await getElectionParties(token)
    setDataSource(data.data.data)
    setData(data.data.data)
 

  }
  const handleDelete = async(id) => {
    // Implement delete logic here
    const res = await deleteParties(id,token)
    getParties()
    
  };  
  useEffect(()=>{
   getParties()
  },[]) 
  const handleChange = (event) => {
    if(!event.target.value){
      setDataSource(data)
      setSearchTerm('')
      return
    }
    setSearchTerm(event.target.value);
    const filtered = data.filter((item) =>
      item.name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDataSource(filtered);
    
  }; 
  return (
    <div>
      <h2 style={{ padding: '0 1rem .5rem 1rem',borderBottom:"solid 1px lightGray" }}>Manage Parties</h2>
      <ManagePartiesTable handleChange={handleChange} searchTerm={searchTerm} dataSource={dataSource} handleDelete={handleDelete} getElectionParties={getParties} token={token}/>
    </div>
  )
}

export default ManageParties
