import React, { useEffect, useState } from 'react'
import ListMediaTableResults from './ListMediaTableExitPo'
import { getExitPolls, getResults } from '../../AadhanApi';
import { Button } from 'antd';
import AddExitPolls from './AddExitPolls';


const ManageExitPolls = () => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const [type,setType]=useState('loksabha_elections');
    const [data,setData] = useState([])
    const permission = localStorage.getItem('USER_PERMISSIONS')
    const resultPermission = JSON.parse(permission)?.Election?.Results;
    const [dataSource,setDataSource] = useState([])
    async function getFetchData(){
        const data = await getExitPolls(token)
        setData(data.data.data)
        let temp = data.data.data.map((e)=>{
            if(e.election_type===type){
                return {...e,mediaSource:e?.media_source?.display_name,type:e?.election_type,state:e?.state?.state_name,year:e?.election_year}
            }
            else{
                return null
            }
        })
        temp=temp.filter((e)=>e!==null)
        setDataSource(temp)
    }
    
    useEffect(()=>{
        let temp = data.map((e)=>{
            if(e.election_type===type){
                return {...e,mediaSource:e?.media_source?.display_name,type:e?.election_type,state:e?.state?.state_name,year:e?.election_year}
            }
            else{
                return null
            }
        })
        temp=temp.filter((e)=>e!==null)
        setDataSource(temp)
    },[type])

   
    useEffect(()=>{
        getFetchData()
    },[])

  return (
    <div style={{margin:"0 1rem"}}>
      <h3>Manage Exit Polls</h3>
      <hr></hr>
      <div>
        <Button type='primary' style={{display:"block",marginLeft:"auto"}}><AddExitPolls getFetchData={getFetchData} data={data} type={type} title={'add'}/></Button>
        <ListMediaTableResults dataSource={dataSource} getFetchData={getFetchData} data={data} type={type} setType={setType}/>
      </div>
    </div>
  )
}

export default ManageExitPolls
