import React, { useState } from 'react';
import { Table, Pagination, Row, Col, Input, Select } from 'antd';
import EditResultModal from './EditResultModal';
import { updateConstituencyResult, updateRoundByRoundConstituencyResult } from '../../AadhanApi';

const { Option } = Select;

const ListResult = ({getData, initialData,selectedRounds,setSelectedRounds }) => {
    const token = localStorage.getItem('ACCESS_TOKEN')
    const [render,setRender]= useState(0);
 
     // Store selected rounds for each constituency

    const handleRoundChange = (result_id, round) => {
        setSelectedRounds({
            ...selectedRounds,
            [result_id]: round,
        });
    };
   

   
    const columns = (result_id,candidateRound,isLast) => [
        {
            title: 'Candidates',
            dataIndex: 'candidates',
            key: 'candidates',
            width:250,
            render: (_, record) => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img
                        src={record?.party?.party_flag_img}
                        style={{
                            width: '22px',
                            margin: '0 1rem 0 0',
                            height: '22px',
                            borderRadius: '50%',
                        }}
                        alt=""
                    />
                    <div>{record?.candidate_name}</div>
                </div>
            ),
        },
        {
            title: () => (
                <div>
                    <Select
                       value={selectedRounds[result_id] || (candidateRound && candidateRound.round_wise_votes ? Object.keys(candidateRound.round_wise_votes).length +(isLast ? 0 : 1) : 1 )}

                        style={{ width: 120 }}

                        onChange={(e) => handleRoundChange(result_id, e)}
                    >
                        {Array.from({ length: candidateRound.round_wise_votes ? Object.keys(candidateRound.round_wise_votes).length + (isLast ? 0 : 1) : 1 }, (_, i) => i + 1).map((num) => (
                            <Option key={num} value={num}>
                                Round {num}
                            </Option>
                        ))}
                    </Select>
                </div>
            ),
            dataIndex: 'round',
            key: 'round',
            render: (_, record) => (
                <div>
                    {record?.round_wise_votes[selectedRounds[result_id]]===undefined? 0 : record?.round_wise_votes[selectedRounds[result_id]]}
                
                    </div>
            ),
        },
    ];

    // Calculate the data to be displayed on the current page
    // const startIndex = (currentPage - 1) * pageSize;
    // const endIndex = startIndex + pageSize;
    const currentPageData = initialData;
    const handleCheckboxChange = async (tableData) => {
        tableData = {...tableData ,is_last_round_votes_updated : !tableData?.is_last_round_votes_updated}
        console.log(tableData)
        await updateConstituencyResult(tableData?.result_id,tableData,token);
        getData();
      };
   
    return (
        <div style={{ overflowY: "auto", overflowX: "auto" ,height:"80%"}}>
            <Row gutter={[11, 11]}>
                {currentPageData?.length!==0 ? [...currentPageData].map((tableData, index) => (
                    <Col
                        span={10}
                        key={index}
                        style={{
                            margin: '.5rem',
                            boxShadow: '2px 0px 5px lightgray',
                            borderRadius: '5px',
                            padding: '.5rem',
                        }}
                    >
                        <h6>{tableData?.constituency?.constituency_name}</h6>
                        {console.log(tableData)}
                        <Table
                             dataSource={tableData?.candidates}
                            columns={columns(tableData?.result_id,tableData?.candidates?.[0],tableData?.is_last_round_votes_updated)}
                            pagination={false}
                            bordered
                            scroll={{ y: 200 }}
                        />
                    <div style={{display:"flex",justifyContent:"end",alignItems:"center"}}> <EditResultModal getData={getData} render={render} setRender={setRender} islast = {tableData?.is_last_round_votes_updated} constituencyName={tableData?.constituency.constituency_name} data={tableData?.candidates} selectedRounds={selectedRounds} result_id={tableData?.result_id} /></div>
                    </Col>
                )):<div style={{height:"20rem",textAlign:"center",padding:"3rem 40%"}}></div>}
            </Row>
            {/* <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={initialData.length}
                onChange={handleChangePage}
                style={{ margin: '1rem', textAlign: 'center' }}
            /> */}
        </div>
    );
};

export default ListResult;
