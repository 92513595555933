import React, { useEffect, useState } from 'react'
import { Button, Checkbox } from 'antd'
import { useNavigate, useParams } from 'react-router'
import { Form } from 'react-router-dom'
import { Content } from 'antd/es/layout/layout'
import { getElectionConstituencies, getElectionStates } from '../../AadhanApi'
import { CaretRightOutlined, CaretDownOutlined ,DoubleRightOutlined,DoubleLeftOutlined} from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import {
    addConstituencies,
    addState,
    handleConstituenciesCheck,
    handleMove,
    handleOpenClick,
    handleisChecked,
    selectAll,
} from '../../store/slice/ElectionSlice'

const MapPhases = () => {
    const typeArray ={assembly_elections:"assembly_constituency",loksabha_elections:"lok_sabha",assembly_bye_elections:"assembly_constituency",loksabha_bye_elections:"lok_sabha"}
    const { val, type } = useParams()
    const token = localStorage.getItem('ACCESS_TOKEN')
    const dispatch = useDispatch()
    const electionStates = useSelector((state) => state.elections.states)
    const navigate = useNavigate()
    if(!electionStates['phase_no'+val]){
        navigate('/home/createElections')
    }
    const constituencies = useSelector(
        (state) => state.elections.constituencies
    )

    const selectedState = useSelector((state)=>state.elections.selectedState)
    const formData = useSelector((state)=>state.elections.formData)
    const [active, setActive] = useState(false)
    
    
    async function getStates() {
        const data = await getElectionStates(token)
        let stateData = data.data.data.map((e) => ({
            ...e,
            isCheckedLeft: false,
            isCheckedRight: false,
            isClickedLeft: false,
            isClickedRight: false,
            direction: 'left',
        }))
        if(formData.electionType==='assembly_elections'){
            stateData = stateData.filter((e)=>e.state_name===selectedState)
            
        }
        dispatch(addState({ phase: 'phase_no' + val, data: stateData }))
    }
    useEffect(() => {
        getStates()
    }, [])
    async function handleOpen(id, direction) {
        dispatch(
            handleOpenClick({
                id: id,
                direction: direction,
                phase: 'phase_no' + val,
            })
        )

        if (
            constituencies &&
            constituencies['phase_no' + val] &&
            constituencies['phase_no' + val][id]
        ) {
            return // Exit early if data already exists
        }

        try {
            const data = await getElectionConstituencies(type, id, token)
            const constituenciesData = data.data.data.map((e) => ({
                ...e,
                isChecked: false,
                direction: 'left',
            }))

            dispatch(
                addConstituencies({
                    id: id,
                    data: constituenciesData,
                    phase: 'phase_no' + val,
                })
            )
        } catch (error) {
            console.error('Error fetching constituencies:', error)
            // Handle error as needed (e.g., show error message to the user)
        }
    }
  

    
    async function handleCheckBox(id, checked, direction) {
        dispatch(
            handleisChecked({
                id: id,
                checked: checked,
                direction: direction,
                phase: 'phase_no' + val,
            })
        )
    }
    function handleSelect(constituencie_id, state_id) {
        
        dispatch(
            handleConstituenciesCheck({
                id: state_id,
                no: constituencie_id,
                phase: 'phase_no' + val,
            })
        )
    }
   
    function handleMove_(direction) {
        setActive(true)
        dispatch(handleMove({ direction: direction, phase: 'phase_no' + val }))
    }
   
    return (
        <div>
            <h5>{'Map Phase ' + val + ' Constituencies'}</h5>
            <hr></hr>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    margin: '1rem',
                }}
            >
                <div>
                    <h6
                        style={{
                            backgroundColor: 'lightGray',
                            padding: '.4rem',
                        }}
                    >
                        States/Constituencies
                    </h6>
                    <Content style={{ height: '22.5rem', overflowY: 'auto' }}>
                        {electionStates['phase_no' + val]?.map((e) => {
                            return (
                                <div>
                                    <div>
                                        {' '}
                                        <span
                                            onClick={() =>
                                                handleOpen(e.state_id, 'left')
                                            }
                                        >
                                            {e.isClickedLeft ? (
                                                <CaretDownOutlined />
                                            ) : (
                                                <CaretRightOutlined />
                                            )}
                                        </span>
                                        <Checkbox
                                            checked={e.isCheckedLeft}
                                            onChange={() =>
                                                handleCheckBox(
                                                    e.state_id,
                                                    !e.isCheckedLeft,
                                                    'left'
                                                )
                                            }
                                        ></Checkbox>{' '}
                                        {e.state_name}
                                    </div>
                                    {e.isClickedLeft &&
                                        constituencies?.length !== 0 && (
                                            <div
                                                style={{ marginLeft: '2.5rem' }}
                                            >
                                                {constituencies &&
                                                    constituencies[
                                                        'phase_no' + val
                                                    ] &&
                                                    constituencies[
                                                        'phase_no' + val
                                                    ][e.state_id] &&
                                                    constituencies[
                                                        'phase_no' + val
                                                    ][e.state_id].map(
                                                        (cons) => {
                                                            // Ensure that cons and e are defined within the scope of this mapping
                                                            if (
                                                                cons.direction ===
                                                                'left'
                                                            ) {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            cons[typeArray[type]+"_no"]
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={
                                                                                cons.isChecked
                                                                            }
                                                                            onChange={() =>
                                                                                handleSelect(
                                                                                    cons[typeArray[type]+"_no"],
                                                                                    cons.state_id
                                                                                )
                                                                            }
                                                                        ></Checkbox>
                                                                        {
                                                                            cons[typeArray[type]+"_name"]
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            return null // Return null for other cases where direction is not 'left'
                                                        }
                                                    )}
                                            </div>
                                        )}
                                </div>
                            )
                        })}
                    </Content>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Button style={{display:"flex",alignItems:"center",marginBottom:"1.5rem"}} onClick={() => handleMove_('right')}>MOVE<DoubleRightOutlined /></Button>
                    <Button style={{display:"flex",alignItems:"center"}} onClick={() => handleMove_('left')}>BACK <DoubleLeftOutlined /></Button>
                </div>
                <div>
                    <h6
                        style={{
                            backgroundColor: 'lightGray',
                            padding: '.4rem',
                        }}
                    >
                        {'Phase ' + val + ' Constituencies List'}
                    </h6>
                    <Content style={{ height: '22.5rem', overflowY: 'auto' }}>
                        {electionStates['phase_no' + val]?.map((e) => {
                            return (
                                <div>
                                    <div>
                                        {' '}
                                        <span
                                            onClick={() =>
                                                handleOpen(e.state_id, 'right')
                                            }
                                        >
                                            {e.isClickedRight ? (
                                                <CaretDownOutlined />
                                            ) : (
                                                <CaretRightOutlined />
                                            )}
                                        </span>
                                        <Checkbox
                                            checked={e.isCheckedRight}
                                            onChange={() =>
                                                handleCheckBox(
                                                    e.state_id,
                                                    !e.isCheckedRight,
                                                    'right'
                                                )
                                            }
                                        ></Checkbox>{' '}
                                        {e.state_name}
                                    </div>
                                    {e.isClickedRight && (
                                        <div style={{ marginLeft: '2.5rem' }}>
                                            {constituencies &&
                                                    constituencies[
                                                        'phase_no' + val
                                                    ] &&
                                                    constituencies[
                                                        'phase_no' + val
                                                    ][e.state_id] &&
                                                    constituencies[
                                                        'phase_no' + val
                                                    ][e.state_id].map(
                                                        (cons) => {
                                                            // Ensure that cons and e are defined within the scope of this mapping
                                                            if (
                                                                cons.direction ===
                                                                'right'
                                                            ) {
                                                                return (
                                                                    <div
                                                                        key={
                                                                            cons[typeArray[type]+"_no"]
                                                                        }
                                                                    >
                                                                        <Checkbox
                                                                            checked={
                                                                                cons.isChecked
                                                                            }
                                                                            onChange={() =>
                                                                                handleSelect(
                                                                                    cons[typeArray[type]+"_no"],
                                                                                    cons.state_id
                                                                                )
                                                                            }
                                                                        ></Checkbox>
                                                                        {
                                                                            cons[typeArray[type]+"_name"]
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                            return null // Return null for other cases where direction is not 'left'
                                                        }
                                                    )}
                                        </div>
                                    )}
                                </div>
                            )
                        })}
                    </Content>
                </div>
            </div>
            <Button style={{display:"block",margin:"1rem auto",padding:"0 1.5rem"}} type="primary" onClick={() => navigate(-1)}>
                save
            </Button>
        </div>
    )
}

export default MapPhases
