import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form, Input, Button, DatePicker, Select } from 'antd'
import dayjs from 'dayjs'
import Search from 'antd/es/input/Search'
import MultiStepForm from './MultiStepForm'
import { getElectionParties, getElectionStates } from '../../AadhanApi'
import CreateElectionForm from './CreateElectionForm'

const { Option } = Select

const CreateElection = () => {
    
    return (
        <div>
            <h2 style={{ padding: '0 1rem 0 1rem' }}>Create Election</h2>
           
            <CreateElectionForm/>
        </div>
    )
}

export default CreateElection
