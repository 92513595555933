import { Button, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import {
    getCandidates,
    getElectionParties,
    getSelectedStateForElection,
    updateElection,
} from '../../AadhanApi'
import { Option } from 'antd/es/mentions'
import { useDispatch, useSelector } from 'react-redux'
import {
    addCandidate,
    addSelectedElectionData,
    getCandidateData,
    removeCandidate,
} from '../../store/slice/ElectionSlice'
import useSelection from 'antd/es/table/hooks/useSelection'

const MapCandidates = ({ title, setIsClicked, election_id, token }) => {
    const typeArray = {
        assembly_elections: 'assembly_constituency',
        loksabha_elections: 'lok_sabha',
        assembly_bye_elections: 'assembly_constituency',
        loksabha_bye_elections: 'lok_sabha',
    }
    const dispatch = useDispatch()
    const [selectedState, setSelectedState] = useState('')
    const [selectedCons, setSelectedCons] = useState('')
    const [state, setState] = useState([])
    const [constituency, setConstituency] = useState([])
    const electionData = useSelector(
        (state) => state.elections.selectedElectionData
    )
    const candidateData = useSelector((state) => state.elections.candidateData)
    const [allCandidates, setAllCandidates] = useState([])
    const [allParty, setAllParty] = useState([])
    const initialCandidateObj = {
        candidate_id: '',
        party: { party_id: '' },
        status: '',
    }
    const [candidateObj, setCandidateObj] = useState(initialCandidateObj)

    async function getState() {
        const data = await getSelectedStateForElection(election_id, token)
        setState(data.data.data['election_data'].states)
        dispatch(
            addSelectedElectionData(data.data.data['election_data'].states)
        )
    }
    async function getCandidatesData() {
        const data = await getCandidates(token)
        setAllCandidates(data.data.data)
    }
    async function getParties() {
        const data = await getElectionParties(token)
     
        setAllParty(data.data.data)
    }


    function handleStateChange(name) {
        setSelectedState('')
        setSelectedCons('')
        setConstituency(
            state.filter((e) => e.state_name === name)[0]['constituencies']
        )
        setSelectedState(name)
    }
    const f = useSelector((state) => state.elections.temp)
    
    function handleConsChange(name) {
        setSelectedCons(name)
        dispatch(
            getCandidateData({
                state_name: selectedState,
                cons_name: name,
                type: title,
            })
        )
    }
    const statusOptions = [
        { value: 'aspirant', label: 'Aspirant' },
        { value: 'incharge', label: 'Incharge' },
        { value: 'announced (B Form)', label: 'Announced (B Form)' },
        { value: 'nomination submitted', label: 'Nomination Submitted' },
        { value: 'nomination accepted', label: 'Nomination Accepted' },
        { value: 'won', label: 'Won' },
        { value: 'lost', label: 'Lost' },
    ]

    useEffect(() => {
        getState()
        getCandidatesData()
        getParties()
    }, [])
    function handleAddObj() {
        if (
            candidateObj.candidate_id !== '' &&
            candidateObj['party'].party_id !== '' &&
            candidateObj.status != ''
        ) {
            dispatch(
                addCandidate({
                    ...candidateObj,
                    state_name: selectedState,
                    cons_name: selectedCons,
                    type: title,
                })
            )
            handleConsChange(selectedCons)
        } else {
            alert('name or status or party is missing')
        }
    }
    const resetFields = () => {
        setCandidateObj(initialCandidateObj)
    }
    
    async function handleSubmit(){
        const choosenState = electionData?.filter((e)=>e.state_name===selectedState)
        const choosenConstituency = electionData[0]?.constituencies.filter((e)=>e[typeArray[title]+"_name"]===selectedCons)
        const state_id = choosenState[0]?.state_id
        const cons_no = choosenConstituency?.[0]?.[typeArray[title]+"_no"]
        const candidatesList = choosenConstituency[0]?.candidates;
        const candidates =[];

       
        for(let i=0;i<candidatesList?.length;i++){
            const candidate = allCandidates.filter((e)=>e.candidate_id===candidatesList[i]?.candidate_id)
            const party = allParty.filter((e)=>e.party_id===candidatesList[i]['party']?.party_id)
            
            const obj={
                "candidate_id": candidate[0]?.candidate_id,
                "full_name": candidate[0]?.full_name,
                "profile_image": candidate[0]?.profile_image,
                "party": {
                  "party_id": party[0]?.party_id ? party[0]?.party_id :0 ,
                  "name": party[0]?.name ? party[0]?.name :'',
                  "abbreviation": party[0]?.abbreviation ? party[0]?.abbreviation :'',
                  "party_flag_img": party[0]?.party_flag_img ? party[0]?.party_flag_img :''
                },
                "status": candidatesList[i]?.status
            }
            candidates.push(obj);
            
        }
        const req = {
            "state_id": state_id,
            "constituency_no": cons_no,
            'candidates':candidates
        }
        
        const res = await updateElection(req,election_id,token) 
    

    }
    function handleRemove(id){
        const obj = {state_name: selectedState,
            cons_name: selectedCons,
            type: title,id:id}
        dispatch(removeCandidate(obj))    

    }
    return (
        <div>
            <Select
                onChange={(e) => handleStateChange(e)}
                style={{ width: 200 }}
            >
                {state.map((e) => (
                    <Option value={e.state_name}>{e.state_name} </Option>
                ))}
            </Select>
            <Select
                onChange={(e) => handleConsChange(e)}
                style={{ width: 200 }}
            >
                {constituency.map((e) => (
                    <Option value={e[typeArray[title] + '_name']}>
                        {e[typeArray[title] + '_name']}{' '}
                    </Option>
                ))}
            </Select>

            {selectedState && selectedCons && (
                <div
                    style={{
                        display: 'flex',
                        width: '80%',
                        border: 'solid 1px lightGray',
                        padding: '1rem',
                        margin: '.4rem',
                        flexDirection: 'column',
                    }}
                >
                    {candidateData.map((e) => {
                        return (
                            <div>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    {' '}
                                    <div>
                                        <span>{selectedState}</span>
                                        <Select
                                            placeholder="select name"
                                            value={e?.candidate_id}
                                            style={{ width: 200 }}
                                        >
                                            {allCandidates.map((ee) => (
                                                <Option value={ee.candidate_id}>
                                                    {ee.full_name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div>
                                        <span>{selectedCons}</span>
                                        <Select
                                            onChange={() => setCandidateObj}
                                            placeholder="select Party"
                                            value={e?.['party']?.party_id}
                                            style={{ width: 200 }}
                                        >
                                            {allParty?.map((e) => (
                                                <Option value={e.party_id}>
                                                    {e.name}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div>
                                        <span>Status</span>
                                        <Select
                                            value={e?.status}
                                            placeholder="select status"
                                            style={{ width: 200 }}
                                        >
                                            {statusOptions.map((option) => (
                                                <Option
                                                    key={option.value}
                                                    value={option.value}
                                                >
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>
                                    </div>
                                    <div>
                                        <span>Remove</span>
                                        <Button onClick={()=>handleRemove(e.candidate_id)}>-</Button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <Button type='primary' style={{width:"10%",margin:".5rem",alignSelf:"end"}} onClick={()=>handleSubmit()}>save</Button>
                    <hr></hr>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                marginTop: '2rem',
                                justifyContent: 'space-between',
                            }}
                        >
                            <div>
                                <span>{selectedState}</span>
                                <Select
                                    placeholder="select name"
                                    value={candidateObj.candidate_id}
                                    onChange={(value) =>
                                        setCandidateObj({
                                            ...candidateObj,
                                            candidate_id: value,
                                        })
                                    }
                                    style={{ width: 200 }}
                                >
                                    {allCandidates.map((ee) => (
                                        <Option
                                            key={ee.candidate_id}
                                            value={ee.candidate_id}
                                        >
                                            {ee.full_name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span>{selectedCons}</span>
                                <Select
                                    value={candidateObj['party'].party_id}
                                    onChange={(value) =>
                                        setCandidateObj((prev) => ({
                                            ...prev,
                                            party: { party_id: value },
                                        }))
                                    }
                                    placeholder="select Party"
                                    style={{ width: 200 }}
                                >
                                    {allParty?.map((ee) => (
                                        <Option
                                            key={ee.party_id}
                                            value={ee.party_id}
                                        >
                                            {ee.name}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span>Status</span>
                                <Select
                                value={candidateObj.status}
                                    placeholder="select status"
                                    style={{ width: 200 }}
                                    onChange={(value) =>
                                        setCandidateObj({
                                            ...candidateObj,
                                            status: value,
                                        })
                                    }
                                >
                                    {statusOptions.map((option) => (
                                        <Option
                                            key={option.value}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </Option>
                                    ))}
                                </Select>
                            </div>
                            <div>
                                <span>Add</span>
                                <Button
                                    onClick={() => {
                                        handleAddObj()
                                        resetFields()
                                    }}
                                >
                                    +
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                style={{
                    position: 'fixed',
                    left: 0,
                    bottom: 0,
                    width: '100%',
                    textAlign: 'center',
                    padding: '10px',
                }}
            >
                <Button type="primary" onClick={() => setIsClicked(false)}>
                    Go Back
                </Button>
            </div>
        </div>
    )
}

export default MapCandidates
