import React, { useRef, useState } from 'react'
import CropperModel from './cropperModal'

function ImageUploader({ getImagePath, onChange, value }) {

    const [file, setFile] = React.useState(null)
    const [isValidFile, setisValidFile] = useState(false)
    const inputRef = useRef(null)
    const onFileInputChange = (e) => {
        if (e?.target?.files && e?.target?.files?.length > 0) {
            const fileInput = e?.target?.files[0]
            if (fileInput?.size / 1024 <= 100) {
                setFile(e?.target?.files[0])
                setisValidFile(true)
            } else {
                setisValidFile(false)
                setFile('')
                inputRef.current.value = null
                alert('The file size should be below 100kb')
            }
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <input
                onClick={() => {
                    inputRef.current.value = null
                }}
                ref={inputRef}
                accept="image/jpeg,image/png"
                type="file"
                onChange={(e) => {
                    onFileInputChange(e)
                }}
            />

            {isValidFile && (
                <CropperModel
                    file={file}
                    onConfirm={(croppedFile) => {
                        getImagePath(croppedFile)
                    }}
                    onDiscard={() => {
                        inputRef.current.value = null
                    }}
                    onCompleted={() => setFile(null)}
                />
            )}
            <a href="https://squoosh.app/" target="_blank">
                Open Squoosh to Format Image
            </a>
            <div style={{ color: '#199be9' }}>Image size 540*525</div>
        </div>
    )
}

export default ImageUploader
