import { Modal, Button, Form, Input, Radio, Image } from 'antd'
import { useEffect, useState } from 'react'
import {getEelectionImageUrl, updateCandidateElection } from '../../AadhanApi'
import ImageUploader from '../image-uploader-crop/ImageUploader'
import { EditFilled, LoadingOutlined } from '@ant-design/icons'

const EditCandidates = ({token,getCandidates,initialData}) => {

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [isLoading, setIsLoading] = useState(false)
    const handleClick = () => {}
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    const getImage = async (path, type) => {
        setProfile('')
        setIsLoading(true)
        const pathUri = await getEelectionImageUrl(path, token)
        setProfile(pathUri.data.url)
        setIsLoading(false)
    }
    useEffect(()=>{
        setProfile(initialData.profile_pic)
        
    },[initialData])
    const [profile, setProfile] = useState()

    async function handleFormSubmit(values) {
       
        values["profile_pic"]=profile
        values['secondary_language']="english"
        const data = await updateCandidateElection(values,initialData.candidate_id,token)
        setProfile()

        handleOk()
        getCandidates()
    }
   
    return (
        <>
            <Button type="primary" onClick={showModal}>
                <EditFilled/>
            </Button>
            <Modal
                title={<h5>Edit Cadidate</h5>}
                closable={false}
                footer={false}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
                
            >
                <Form  
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={handleFormSubmit}
                    initialValues={initialData}
            
                    >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter name',
                            },
                        ]}
                        name="full_name"
                        label="Name"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: profile ? false : true,
                                message: profile ? '' : 'Please upload profile',
                            },
                        ]}
                        name="profile_pic"
                        label="Candidate Profile"
                    >
                        {profile && (
                            <Image
                                style={{
                                    padding: '1px',
                                    borderRadius: '5px',
                                    boxShadow: '2px 5px 5px gray',
                                    border: 'solid 1px gray',
                                }}
                                width={200}
                                src={profile}
                                alt="Uploaded Image"
                            />
                        )}
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                marginTop: '1rem',
                            }}
                        >
                            <ImageUploader getImagePath={getImage} />
                        </div>
                        <p>
                            {isLoading && (
                                <span>
                                    Uploading... <LoadingOutlined />
                                </span>
                            )}{' '}
                        </p>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter age',
                            },
                        ]}
                        name="age"
                        label="Age"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please select gender',
                            },
                        ]}
                        name="gender"
                        label="Gender"
                    >
                        <Radio.Group>
                            <Radio value="male">Male</Radio>
                            <Radio value="female">Female</Radio>
                        </Radio.Group>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ display: 'block', margin: '0 auto' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default EditCandidates
