export const electionType = [
    {
        label: 'Assembly Elections',
        value: 'assembly_elections',
    },
    {
        label: 'Loksabha Elections',
        value: 'loksabha_elections',
    },
]
