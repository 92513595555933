import React, { useEffect, useState } from 'react'
import { deleteCandidates, getCandidates } from '../../AadhanApi'
import CandidateTable from './CandidateTable';
import AddCandidate from './AddCandidate';

const ManageCandidate = () => {
  const [data,setData] =useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [dataSource,setDataSource] = useState([]);
  const [dataLoading,setDataLoading] =useState(false)
 
  const token = localStorage.getItem('ACCESS_TOKEN')
  async function getCanditatesfunc(){
    setDataLoading(true)
    const data = await getCandidates(token)
    setDataSource(data.data.data)
    setData(data.data.data)
    setDataLoading(false)
  }


  
  const handleDelete = async(id) => {
    const res = await deleteCandidates(id,token)
    getCanditatesfunc()
  };  
  useEffect(()=>{
   getCanditatesfunc()
  },[])  

  const handleChange = (event) => {
   
    if(!event.target.value){
      setDataSource(data)
      setSearchTerm('')
      return
    }
    setSearchTerm(event.target.value);
    const filtered = data.filter((item) =>
      item.full_name.toLowerCase().includes(event.target.value.toLowerCase())
    );
    setDataSource(filtered);
    
  };



 
  return (
    <div>
      <h2 style={{ padding: '0 1rem .5rem 1rem',borderBottom:"solid 1px lightGray" }}>Manage Candidates</h2>
      <div style={{display:"flex",justifyContent:'flex-end',padding:'0.7rem'}}>
      <input
        type="text"
        
        placeholder="Search candidate..."
        value={searchTerm}
        onChange={handleChange}
        style={{border:"solid lightgray 1px",borderRadius:"5px",outline:"none",marginRight:"2rem"}}
      />
      <AddCandidate token={token} getCandidates={getCanditatesfunc} />
      </div>
      <CandidateTable dataLoading={dataLoading} dataSource={dataSource} handleDelete={handleDelete} getCandidates={getCanditatesfunc} token={token}/>
    </div>
  )
}

export default ManageCandidate


// 