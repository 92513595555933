import React, { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Image, Input, Modal, Select } from 'antd'
import {
    
    createMedia,
    getEelectionImageUrl,
    updateMedia,
   
    
} from '../../AadhanApi'
import { EditOutlined} from '@ant-design/icons';
import { Option } from 'antd/es/mentions'
import ImageUploader from '../image-uploader-crop/ImageUploader'
import { LoadingOutlined } from '@ant-design/icons'

const EditMediaForm = ({getMedia,token,electionStates ,initialData }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [logo, setlogo] = useState(initialData.logo)
    const [isLoading, setIsLoading] = useState(false)
    
    const showModal = () => {
        setIsModalOpen(true)
        
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    useEffect(() => {
       
        setlogo(initialData.logo)
        initialData['state_name']=initialData['state'].state_name
        form.setFieldsValue(initialData);
      }, [initialData]);
    function createRequest(values) {
        const foundState = electionStates.find(state => state.state_name === values.state_name);     
    
        
        return {
           name:values.name,
           logo:logo,
           display_name:values.display_name,
            state: 
                {
                    state_id: foundState ? foundState?.state_id :null,
                    state_cd: foundState ? foundState?.state_cd :null,
                    state_name: values.state_name,
                },
            
        }
    }
    async function handleFormSubmit(values) {
        const req = createRequest(values)
       
        const res = await updateMedia(req,initialData.media_id,token)
        
        form.resetFields()
        setlogo()
        
        handleOk();
        getMedia()
        
    }

    const getImagePathlogo = async (path, type) => {
        setlogo('')
        setIsLoading(true)
        const pathUri = await getEelectionImageUrl(path, token)
        setlogo(pathUri.data.url)
        setIsLoading(false)
    }
  
    

    return (
        <>
            <div  onClick={showModal}>
                <EditOutlined/>
            </div>
            <Modal
                title={<h5>Add Media</h5>}
                closable={false}
                footer={false}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}

            >
             <hr></hr>   
                <Form
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={handleFormSubmit}
                    form={form}
            
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter name',
                            },
                        ]}
                        name="name"
                        label="Name"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                   
                    <Form.Item
                        rules={[
                            {
                                required: logo ? false : true ,
                                message:logo ?'' : 'Please upload logo',
                            },
                        ]}
                        name="logo"
                        label="Logo"
                    >
                        {logo && (
                            <Image
                                style={{
                                    padding: '1px',
                                    borderRadius: '5px',
                                    boxShadow: '2px 5px 5px gray',
                                    border: 'solid 1px gray',
                                }}
                                width={200}
                                src={logo}
                                alt="Uploaded Image"
                            />
                        )}
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                marginTop: '1rem',
                            }}
                        >
                            <ImageUploader  getImagePath={getImagePathlogo} />
                        </div>
                        <p>
                            {isLoading && (
                                <span>
                                    Uploading... <LoadingOutlined />
                                </span>
                            )}{' '}
                        </p>
                    </Form.Item>
                     <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please select state',
                            },
                        ]}
                        name="state_name"
                        label="Select State"
                    >
                        <Select
                            
                            placeholder="-select state-"
                            style={{ width: 200 }}
                        >
                            {electionStates?.map((e) => (
                                <Option value={e.state_name} key={e.state_id}>
                                    {e.state_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter display name',
                            },
                        ]}
                        name="display_name"
                        label="Display Name"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    
                    <Form.Item>
                        <Button
                            style={{ display: 'block', margin: '0 auto' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default EditMediaForm
