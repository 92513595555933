import React from 'react'
import { Table} from 'antd';
const ElectionTable = ({dataSource,columns,dataloading}) => {
  
  return (
    <div>
       <Table dataSource={dataSource} loading={dataloading} columns={columns}  />
    </div>
  )
}

export default ElectionTable
