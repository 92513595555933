import React, { useEffect, useState } from 'react'
import { Space, Spin } from 'antd'
import AddPulsePollsForm from './AddPulsePollsForm'
import { DeleteOutlined } from '@ant-design/icons'
import ElectionTable from '../ElectionCommonTable/ElectionTable'

const ManagePulsePollsTable = ({ dataSource, dataloading, stateId, token , electionType}) => {
    const columns = [
        {
            title: 'Poll Title',
            dataIndex: 'title',
            key: 'title',
        },
        {
            title: 'Published Date',
            dataIndex: 'publishedDate',
            key: 'publishedDate',
            render: (text, record) => {
                const date = new Date(record?.publishedDate * 1000)
                const year = date.getFullYear()
                const month = date.getMonth() + 1
                const day = date.getDate()
                const hours = date.getHours()
                const minutes = date.getMinutes()
                const seconds = date.getSeconds()
                return `${year}-${month < 10 ? '0' : ''}${month}-${
                    day < 10 ? '0' : ''
                }${day} ${hours}:${minutes}:${seconds}`
            },
        },
        {
            title: 'Poll Options',
            dataIndex: 'options',
            key: 'options',
            render: (options) => (
                <Space>
                    {options.map((item) => (
                        <div key={item._id}>
                            <img
                                src={item.image}
                                alt={item.text}
                                style={{
                                    width: 30,
                                    height: 30,
                                    marginRight: 5,
                                }}
                            />
                            <span>{item.text}</span>
                        </div>
                    ))}
                </Space>
            ),
        },
        {
            title: 'Poll Type',
            dataIndex: 'polltype',
            key: 'polltype',
        },
    ]

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '.4rem',
                }}
            >
                <AddPulsePollsForm token={token} />
            </div>
            {/* // Conditionally render based on dataLoading state */}
                
                <ElectionTable dataSource={dataSource} dataloading={dataloading} columns={columns} /> 
                {/* // Render ElectionTable when data is loaded */}
            
        </div>
    )
}

export default ManagePulsePollsTable
