import { Outlet, useNavigate } from 'react-router'
import SideNavbar from '../SideNavbar/SideNavbar'
import { useEffect, useState } from 'react'
import {
    getAllEmployees,
    getAllLocation,
    getCategory,
    getLanguage,
    getStates,
} from '../../AadhanApi'
import { useDispatch, useSelector } from 'react-redux'
import {
    addCategories,
    addEmployeeId,
    addEmployeeName,
    addEmployees,
    addLocations,
    addPermissions,
    addToken,
    addlanguage,
} from '../../store/slice/AppSlice'
import { LoadingOutlined } from '@ant-design/icons'
import { message } from 'antd'
import { Link } from 'react-router-dom'
import { userLogin } from '../../store/slice/AppSlice'

const Home = () => {
    const [isLoadingData, setIsLoadingData] = useState(false)
    const dispatch = useDispatch()
    const accessToken = useSelector((state) => state.app.accessToken)
    const userPermissions = useSelector((state) => state.app.userPermissions)
    const navigate = useNavigate()

    const getToken = () => {
        if (accessToken && accessToken !== '') {
            return accessToken
        } else {
            const token = localStorage.getItem('ACCESS_TOKEN')
            if (token && token !== '') {
                return token
            }
        }
    }

    const [messageApi, contextHolder] = message.useMessage()
    const displayMessage = (messageType, messageToDisplay) => {
        localStorage.removeItem('ACCESS_TOKEN')
        if (messageType === 'error') {
            messageApi.open({
                type: 'error',
                content: (
                    <div style={{ display: 'flex', gap: '5px' }}>
                        {messageToDisplay}
                        <Link to="/">
                            Click here to login or will be redirected to Login
                            page in 5 seconds
                        </Link>
                    </div>
                ),
                duration: 10,
            })
            setTimeout(() => {
                navigate('/')
            }, 5000)
        }
    }

    const fetchInitialData = async () => {
        setIsLoadingData(true)
        try {
            const languageResponse = await getLanguage('Active', getToken())
            dispatch(addlanguage(languageResponse?.data))

            const categoryResponse = await getCategory(
                '99999',
                'all',
                getToken(),
                'active'
            )
            dispatch(addCategories(categoryResponse?.data))

            const allLocationResponse = await getStates(getToken())
            dispatch(addLocations(allLocationResponse?.data))

            const allEmployeeResponse = await getAllEmployees(getToken())
            dispatch(addEmployees(allEmployeeResponse?.data))
            setIsLoadingData(false)
        } catch (err) {
            console.log('error is :::', err)
            setIsLoadingData(false)
            if (err?.response?.status === 401) {
                displayMessage(
                    'error',
                    'You are unauthorised, Please login again'
                )
            } else {
                if(err.response.data.detail.error == 'Signature has expired') {
                    localStorage.removeItem('ACCESS_TOKEN')
                    localStorage.removeItem('USER_PERMISSIONS')
                    localStorage.removeItem('EMPLOYEE_ID')
                    localStorage.removeItem('EMPLOYEE_NAME')
                    dispatch(userLogin({}))
                    navigate('/')
                } else {
                    messageApi.open({
                        type: 'error',
                        content: 'Something Failed! Please refresh and try again',
                        duration: 5,
                    })
                }
            }
        }
    }

    if (!accessToken || accessToken === '') {
        const token = localStorage.getItem('ACCESS_TOKEN')
        const employeeId = localStorage.getItem('EMPLOYEE_ID')
        const employeeName = localStorage.getItem('EMPLOYEE_NAME')
        if (employeeId) {
            dispatch(addEmployeeId(employeeId))
        }
        if (employeeName) {
            dispatch(addEmployeeName(employeeName))
        }
        if (token && token !== '') {
            dispatch(addToken(token))
        } else {
            navigate('/')
        }
    }

    useEffect(() => {
        fetchInitialData()
        if (userPermissions && Object.keys(userPermissions)?.length === 0) {
            const perms = localStorage.getItem('USER_PERMISSIONS')
            dispatch(addPermissions(JSON.parse(perms)))
        }
    }, [])
    return (
        <div style={{ height: '100%' }}>
            {contextHolder}
            {isLoadingData ? (
                <div
                    style={{
                        position: 'absolute',
                        top: '40%',
                        left: '45%',
                    }}
                >
                    <LoadingOutlined style={{ fontSize: '5rem' }} />
                </div>
            ) : (
                <div>
                    <SideNavbar />
                    <div className="component-container">
                        <Outlet />
                    </div>
                </div>
            )}
        </div>
    )
}
export default Home
