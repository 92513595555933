import { Form, Input } from 'antd'
import { useEffect } from 'react'
import {
    updateAssemblyConstituencies,
    updateState,
    updateDistrict,
    updateMandal,
} from '../../AadhanApi'

const EditLocation = ({
    selectedRole,
    getTableData,
    setShowModal,
    accessToken,
    selectedLocation,
    selectedDistrictKey,
    selectedMandalKey,
    selectedStateKey,
    selectedAssemblyConstituencyKey,
    selectedRecord,
}) => {
    const [form] = Form.useForm()
    const onFinish = async (values) => {
        if (selectedRole) {
            // await updateRole("obj", accessToken, selectedRole.id)
        } else {
            if (selectedLocation === 'Assembly') {
                await updateAssemblyConstituencies(
                    values.name,
                    values.local_name,
                    selectedAssemblyConstituencyKey,
                    accessToken
                )
            } else if (selectedLocation === 'State') {
                await updateState(
                    values.name,
                    values.local_name,
                    selectedStateKey,
                    accessToken
                )
            } else if (selectedLocation === 'District') {
                await updateDistrict(
                    values.name,
                    values.local_name,
                    selectedDistrictKey,
                    accessToken
                )
            } else if (selectedLocation === 'Mandal') {
                await updateMandal(
                    values.name,
                    values.local_name,
                    selectedMandalKey,
                    accessToken
                )
            }
        }

        getTableData()
        setShowModal(false)
        form.resetFields()
    }

    useEffect(() => {
        if (selectedRecord) {
            form.setFieldsValue({
                name: selectedRecord?.name,
                local_name: selectedRecord?.local_name,
            })
        } else {
            form.resetFields()
        }
    }, [selectedRecord])

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    return (
        <Form
            id="roleForm"
            form={form}
            name="roleForm"
            scrollToFirstError
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
        >
            <Form.Item
                labelCol={{ span: 24 }}
                label={selectedLocation}
                name="name"
                rules={[{ required: true, message: 'Please enter name' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 24 }}
                label="Local Name"
                name="local_name"
                rules={[{ required: false, message: 'Please enter name' }]}
            >
                <Input />
            </Form.Item>
        </Form>
    )
}

export default EditLocation
