import { EditFilled } from "@ant-design/icons";
import { Button, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { updateRoundByRoundConstituencyResult } from "../../AadhanApi";

const EditResultModal = ({
    constituencyName,
    data,
    selectedRounds,
    result_id,
    getData,
    islast
}) => {
    const round = data[0]?.round_wise_votes
        ? Object.keys(data[0]?.round_wise_votes).length + (islast ?0 : 1)
        : 1;

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [initialData, setInitialData] = useState({});
    const token = localStorage.getItem('ACCESS_TOKEN')
    const [check,setCheck] = useState(islast===undefined ? false : islast);
    console.log(islast,"fdf",check)
    const currentRound = selectedRounds[result_id] === undefined ? round : selectedRounds[result_id];
    useEffect(() => {
        const temp = {};
        data.forEach((event) => {
            const defaultValue =
                event.round_wise_votes[
                    selectedRounds[result_id] === undefined
                        ? round
                        : selectedRounds[result_id]
                ];
            temp[event.candidate_id] = defaultValue || 0;
        });
        setInitialData(temp);
    }, [data, selectedRounds, result_id, round]);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleChange = (e, candidate_id) => {
        const { value } = e.target;
        setInitialData((prevData) => ({
            ...prevData,
            [candidate_id]: value,
        }));
    };
    async function onFinish() {
        // Check if any input field is empty or does not contain a valid number
        const isValid = Object.values(initialData).every(value => {
            // Check if the value is not empty and is a valid number
            return value !== '' && !isNaN(value);
        });
    
        if (isValid) {
        
            const dataArray = Object.entries(initialData).map(([candidate_id, votes]) => ({ candidate_id: parseInt(candidate_id), votes }));
            
            const req={
                "round_no": selectedRounds[result_id] === undefined ? round : selectedRounds[result_id],
                "data":dataArray ,
                "is_last_round":check
            }
            const res = await updateRoundByRoundConstituencyResult(result_id,req,token) 
            if(res.data.success){
                handleOk()
                getData()

            }
            else{
                console.log('API Error While Updating Round Wise Votes')
            }
           
        } else {
            // Validation failed, inform the user
            Modal.error({
                title: 'Validation Error',
                content: 'Please make sure all fields contain valid numbers.',
            });
        }
    }
    return (
        <>
            <Button
                style={{
                    display: 'block',
                    float: 'right',
                    margin: '5px 1.5rem 5px 5px',
                    textAlign: 'center',
                }}
                size="small"
                ghost
                type="primary"
                onClick={showModal}
            >
                Edit <EditFilled />
            </Button>
            <Modal
                title="Edit Constituency Result"
                closable={true}
                footer={false}
                onOk={handleOk}
                onCancel={handleCancel}
                open={isModalOpen}
            >
                <hr />
                <div style={{display:"flex",justifyContent:"space-between",padding:"0 4rem 0 0"}}>
                <h6 style={{margin:"0 .5rem"}}>{constituencyName}</h6>
                <h6 >{"Round " + currentRound}</h6>
                </div>
                <div style={{border:"solid lightGray 1px",borderRadius:"5px"}}>

                {data.map((event) => (
                    <div  key={event.candidate_id} style={{display:"flex",justifyContent:"space-between",margin:".5rem",padding:"0 0 0 0"}}>
                        <div > <img
                        src={event?.party?.party_flag_img}
                        style={{
                            width: '22px',
                            margin: '0 1rem 0 0',
                            height: '22px',
                            borderRadius: '50%',
                        }}
                        alt=""
                        />{event.candidate_name}</div>
                        <div >
                            <Input type="number"
                                onChange={(e) => handleChange(e, event.candidate_id)}
                                value={initialData[event.candidate_id]}
                                />
                        </div>
                    </div>

))}
</div>
<div style={{display:"flex",justifyContent:"center",marginTop:"1rem"}}><div >Is Last Round</div><div style={{marginRight:"5rem"}}> <input checked={check} onChange={(e)=>setCheck(!check)} type='checkbox' style={{ width: '12px', height: '12px', transform: 'scale(1.5)',marginLeft:"1rem" }}/></div></div>
                <Button style={{display:"block",margin:"1rem auto 0 auto"}} type="primary" ghost onClick={()=>onFinish()}>Update</Button>
            </Modal>
        </>
    );
};

export default EditResultModal;
