import React, { useEffect, useState } from 'react'
import CommonTable from '../CommonTable/CommonTable'
import {
    getConstituencyResult,
    getElectionDistricts,
    getElectionStates,
} from '../../AadhanApi'
import { Button, Select, Spin } from 'antd'
import { Option } from 'antd/es/mentions'
import ListResult from './ListResults'

const ConstituencyResultsOverview = () => {
    const [state, setState] = useState([])
    const [selectedRounds, setSelectedRounds] = useState({})
    const [selectedState, setSelectedState] = useState(1)
    const [type, setType] = useState('assembly_elections')
    const token = localStorage.getItem('ACCESS_TOKEN')
    const [district, setDistrict] = useState([])
    const [selectedDistrict, setSelectedDistrict] = useState('')
    const currentYear = new Date().getFullYear()
    const [year, setYear] = useState(currentYear)
    const years = Array.from({ length: 6 }, (_, index) => currentYear + index)
    const [result, setResult] = useState([])
    const [initialData, setInitialData] = useState([])
    const [fetch, setFetch] = useState(false)
    async function getStateFunc() {
        // const data = await getElectionStates(token)
        setState([
            {
                labe: 'Andhra Pradesh',
                state_name: 'Andhra Pradesh',
                state_id: 1,
                state_cd: 'S01',
            },
        ])
    }

    async function getDistrict() {
        if (type === 'assembly_elections') {
            const district = await getElectionDistricts(selectedState, token)
            setDistrict(district.data.data)
            setSelectedDistrict(district.data.data[0].district_cd)
            return
        }

        setDistrict([])
        setSelectedDistrict('')
    }

    useEffect(() => {
        getStateFunc()
    }, [])
    useEffect(() => {
        setSelectedDistrict(district[0]?.district_cd)
    }, [type, district])
    async function getData() {
        setFetch(true)
        setSelectedRounds({})
        // setResult([]);
        if (type === 'loksabha_elections') {
            const data = await getConstituencyResult(
                token,
                type,
                year,
                selectedState
            )
            setResult(data.data.data)
            return
        }
        if (selectedDistrict) {
            const data = await getConstituencyResult(
                token,
                type,
                year,
                selectedState,
                selectedDistrict
            )
            setResult(data.data.data)
        }
        setFetch(false)
    }

    useEffect(() => {
        getDistrict()
    }, [selectedState, type])

    useEffect(() => {
        getData()
        setSelectedRounds({})
    }, [selectedState, selectedDistrict, year, type])
    return (
        <div style={{ padding: '0 1rem', height: '87vh' }}>
            <h2>Constituency Results Overview</h2>
            <hr></hr>
            <div style={{ marginBottom: '1rem' }}>
                <Select
                    onChange={(e) => setType(e)}
                    value={type}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    placeholder="Select Type"
                >
                    {/* <Option value="loksabha_elections" key="loksabha_elections">
                        Loksabha
                    </Option> */}
                    <Option value="assembly_elections" key="assembly_elections">
                        Assembly
                    </Option>
                </Select>
                <Select
                    showSearch
                    onChange={(e) => setSelectedState(e)}
                    value={selectedState}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    placeholder="Select Type"
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                >
                    {state.map((e) => (
                        <Option value={e.state_id} key={e.state_id}>
                            {e.state_name}
                        </Option>
                    ))}
                </Select>
                <Select
                    
                    showSearch
                    onChange={(e) => setSelectedDistrict(e)}
                    placeholder="Select District"
                    value={selectedDistrict || undefined}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                        option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                    }
                >
                    {district?.map((e) => (
                        <Option key={e.district_id} value={e.district_cd}>
                            {e.district_name}
                        </Option>
                    ))}
                </Select>
                <Select
                    onChange={(e) => setYear(e)}
                    value={year}
                    style={{ width: 120, margin: '0 2rem 0 0' }}
                    placeholder="Select Year"
                >
                    {years.map((year) => (
                        <Option key={year} value={year}>
                            {year}
                        </Option>
                    ))}
                </Select>
                <Button onClick={() => getData()} type="primary" ghost>
                    Refresh
                </Button>
            </div>
            {fetch ? (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '100%',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Spin />
                </div>
            ) : (
                <ListResult
                    getData={getData}
                    selectedRounds={selectedRounds}
                    setSelectedRounds={setSelectedRounds}
                    initialData={result}
                />
            )}
        </div>
    )
}

export default ConstituencyResultsOverview
