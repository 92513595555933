import React from 'react'
import { Table, DatePicker, Button ,Input} from 'antd'
import { useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { dateChange, phaseChange } from '../../store/slice/ElectionSlice'

const PhasesTable = ({ electionType }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const noOfPhases = useSelector((state) => state.elections.initialPhase)
    const dataSource = Array.from({ length: noOfPhases }, (_, index) => ({
        key: String(index + 1),
        phase: `Phase ${index + 1}`,
        btn: 'Map Now',
    }))
    
    const selectedState =useSelector((state)=>state.elections.selectedState)
    const formData = useSelector((state)=>state.elections.formData)
    const { RangePicker } = DatePicker
    function handleDateChange(newDate, key) {
        dispatch(dateChange({ id: key, date: newDate }))
      
    }
    function handlePhaseChange(value, key) {
      dispatch(phaseChange({ id: key, phase: value }))
    }
    const handleButtonClick = (key) => {
      
        
    }
    const handleValidation = (phase,date,e) => {
    if((formData.electionType ==='assembly_election') && (selectedState==='' || selectedState===undefined) ){
        alert('please select any state and then map !');
        return
    }    
    if(phase===undefined){
        alert('Validation failed! Please enter a valid phase (e.g., "phase 1").');
        return 
     }
     if(date===undefined || date==='' ||date===null){
        alert('please select phase date to continue map phaes !');
        return
     }
      const pattern = /^phase\s\d+$/;
  
      if (phase==='' || phase.trim() === '' || !pattern.test(phase) ) {
       
        alert('Validation failed! Please enter a valid phase (e.g., "phase 1").');
        return; 
      }
      // Validation successful, proceed with the action
      e.preventDefault()
      
    };

    const columns = [
        {
            title: 'Phases',
            dataIndex: 'phase',
            key: 'phase',
            width: '30%',
            render: (phase, record) => (
                <Input
                    defaultValue={phase}
                    placeholder='phase 1'
                    onChange={(newPhase) =>
                        handlePhaseChange(newPhase.target.value, record.key)
                    }
                />
            ),
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            width: '30%',
            render: (date, record) => (
                <DatePicker
                    defaultValue={date}
                    onChange={(newDate) =>
                        handleDateChange(newDate, record.key)
                    }
                />
            ),
        },
        {
            title: 'Map states/Constituencies',
            dataIndex: 'btn',
            key: 'region',
            width: '40%',
            render: (text, record) => (
                <Link
                    onClick={(e) => {
                      handleValidation(record.phase,record.date,e);
                      
                        if(record.phase!==undefined){
                          navigate(
                            `/home/mapPhases/phase/${parseInt((record.phase).split(' ')[1])}/${formData.electionType}`
                          )
                          
                        }
                    }}
                >
                    {text}
                </Link>
            ),
        },
    ]


    return (
        <Table
            columns={columns}
            dataSource={noOfPhases}
            pagination={false}
            expandable={{ defaultExpandAllRows: true }}
        />
    )
}

export default PhasesTable
