import { message, Button, Modal, Popconfirm } from 'antd'
import CommonTable from '../CommonTable/CommonTable'
import {
    districtDelete,
    getStates,
    stateDelete,
    deleteAssemblyConstituencies,
    deleteMandal,
} from '../../AadhanApi'
import { useSelector } from 'react-redux'
import { useState, useEffect } from 'react'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons'
import './Locations.scss'
import AddLocation from './AddLocation'
import EditLocation from './EditLocation'
import { rectSwappingStrategy } from '@dnd-kit/sortable'

const Locations = () => {
    const [statesData, setStatesData] = useState([])
    const [assemblyconstituenciesData, setAssemblyconstituenciesData] =
        useState([])
    const [districtsData, setDistrictsData] = useState([])
    const [mandalsData, setMandalsData] = useState([])
    const [areasData, setAreasData] = useState([])
    const [dataLoading, setDataLoading] = useState(false)
    const accessToken = useSelector((state) => state.app.accessToken)
    const [messageApi, contextHolder] = message.useMessage()
    const [selectedStateKey, setSelectedStateKey] = useState(null)
    const [
        selectedAssemblyConstituencyKey,
        setselectedAssemblyConstituencyKey,
    ] = useState(null)
    const [selectedDistrictKey, setSelectedDistrictKey] = useState(null)
    const [selectedMandalKey, setSelectedMandalKey] = useState(null)
    const [isUpdateMode, setIsUpdateMode] = useState(false)
    const [showRoleModal, setShowRoleModal] = useState(false)
    const [selectedLocation, setSelectionLocation] = useState('')
    const [selectedRecord, setSelectedRecord] = useState(null)
    const openModal = (loc, mode, record) => {
        setSelectionLocation(loc)
        setSelectedRecord(record)
        setShowRoleModal(true)
        setIsUpdateMode(mode === 'update')
    }

    const stateTableColumns = [
        {
            title: (
                <div className="location-align">
                    States
                    <div className="add-btn" onClick={() => openModal('State')}>
                        Add New
                    </div>
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        className="hoverState"
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                        <div>
                            <EditOutlined
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    openModal('State', 'update', record)
                                }}
                            />
                            <Popconfirm
                                title="Delete Row"
                                description="Are you sure to delete this row?"
                                onConfirm={() => stateDeleteRow(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{
                                        marginLeft: '5px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        },
    ]

    const assemblyConstituenciesTableColumns = [
        {
            title: (
                <div className="location-align">
                    Assembly Constituencies
                    <div
                        className="add-btn"
                        onClick={() => openModal('Assembly Constituencies')}
                    >
                        Add New
                    </div>
                </div>
            ),
            dateIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        className="hoverState"
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                        <div>
                            <EditOutlined
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    openModal('Assembly', 'update', record)
                                }}
                            />
                            <Popconfirm
                                title="Delete Row"
                                description="Are you sure to delete this row?"
                                okText="Yes"
                                onConfirm={() =>
                                    assemblyConstituenciesDeleteRow(record)
                                }
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{
                                        marginLeft: '5px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        },
    ]

    const districtTableColumns = [
        {
            title: (
                <div className="location-align">
                    Districts
                    {selectedStateKey && (
                        <div
                            className="add-btn"
                            onClick={() => openModal('District')}
                        >
                            Add New
                        </div>
                    )}
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        className="hoverState"
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                        <div>
                            <EditOutlined
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    openModal('District', 'update', record)
                                }}
                            />
                            <Popconfirm
                                title="Delete Row"
                                description="Are you sure to delete this row?"
                                okText="Yes"
                                onConfirm={() => districtDeleteRow(record)}
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{
                                        marginLeft: '5px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        },
    ]

    const mandalTableColumns = [
        {
            title: (
                <div className="location-align">
                    Mandals
                    {selectedDistrictKey && (
                        <div
                            className="add-btn"
                            onClick={() => openModal('Mandal')}
                        >
                            Add New
                        </div>
                    )}
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        className="hoverState"
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                        <div>
                            <EditOutlined
                                style={{ paddingRight: '5px' }}
                                onClick={() => {
                                    openModal('Mandal', 'update', record)
                                }}
                            />
                            <Popconfirm
                                title="Delete Row"
                                description="Are you sure to delete this row?"
                                onConfirm={() => mandalDeleteRow(record)}
                                okText="Yes"
                                cancelText="No"
                            >
                                <DeleteOutlined
                                    style={{
                                        marginLeft: '5px',
                                        cursor: 'pointer',
                                    }}
                                />
                            </Popconfirm>
                        </div>
                    </div>
                )
            },
        },
    ]

    const areaTableColumns = [
        {
            title: (
                <div className="location-align">
                    Areas
                    {selectedMandalKey && (
                        <div
                            className="add-btn"
                            onClick={() => openModal('Area')}
                        >
                            Add New
                        </div>
                    )}
                </div>
            ),
            dataIndex: 'name',
            key: 'name',
            render: (_, record) => {
                return (
                    <div
                        style={{
                            display: 'flex',
                            gap: '5px',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                        className="hoverState"
                    >
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                alignItems: 'center',
                            }}
                        >
                            <p style={{ marginBottom: '0px' }}>
                                {record?.name}
                            </p>
                        </div>
                    </div>
                )
            },
        },
    ]

    useEffect(() => {
        getStatesDetails()
    }, [])

    const getStatesDetails = async () => {
        setDataLoading(true)
        try {
            const response = await getStates(accessToken)
            setStatesData(response.data)
            if (selectedStateKey) {
                const filteredState = response.data?.find(
                    (ele, i) => ele.state_id == selectedStateKey
                )
                setAssemblyconstituenciesData(
                    filteredState?.assembly_constituencies
                )
                if (true) {
                    const filteredAssemblyConstituency =
                        filteredState?.assembly_constituencies?.find(
                            (ele, i) =>
                                ele.assembly_constituency_id ==
                                selectedAssemblyConstituencyKey
                        )

                    setDistrictsData(filteredState?.districts)
                    if (selectedDistrictKey) {
                        const filteredDistrict = filteredState?.districts?.find(
                            (ele, i) => ele.district_id == selectedDistrictKey
                        )
                        setMandalsData(filteredDistrict?.mandals)
                        if (selectedMandalKey) {
                            let filteredMandal =
                                filteredDistrict?.mandals?.find(
                                    (ele, i) =>
                                        ele.mandal_id == selectedMandalKey
                                )
                            let mappedData = filteredMandal?.areas?.map(
                                (area) => ({
                                    name: area,
                                })
                            )
                            setAreasData(mappedData)
                        }
                    }
                }
            }

            setDataLoading(false)
        } catch (err) {
            setStatesData([])
            setDataLoading(false)
            console.log(err)
            messageApi.open({
                type: 'error',
                content: 'Failed to get data',
                duration: 5,
            })
        }
    }

    const stateClassName = (record) => {
        return record.state_id === selectedStateKey ? 'selected-row' : ''
    }

    const assemblyConstituenciesClassName = (record) => {
        return record.assembly_constituency_id ===
            selectedAssemblyConstituencyKey
            ? 'selected-row'
            : ''
    }

    const districtClassName = (record) => {
        return record.district_id === selectedDistrictKey ? 'selected-row' : ''
    }

    const mandalClassName = (record) => {
        return record.mandal_id === selectedMandalKey ? 'selected-row' : ''
    }

    const onStateSelectRow = (record) => {
        setSelectedStateKey(record.state_id)
        setAssemblyconstituenciesData(record?.assembly_constituencies)
        setDistrictsData(record?.districts)
        setMandalsData([])
        setSelectedDistrictKey(null)
        setselectedAssemblyConstituencyKey(null)
    }

    const onAssemblySelectRow = (record) => {
        setselectedAssemblyConstituencyKey(record.assembly_constituency_id)
    }

    const onDistrictSelectRow = (record) => {
        setSelectedDistrictKey(record.district_id)
        setMandalsData(record?.mandals)
    }

    const onMandalSelectRow = (record) => {
        setSelectedMandalKey(record.mandal_id)
        let mappedData = record?.areas?.map((area) => ({ name: area }))
        setAreasData(mappedData)
    }
    const stateDeleteRow = (record) => {
        stateDelete(record.state_id, accessToken)
        getStatesDetails()
        getStatesDetails()
    }
    const districtDeleteRow = (record) => {
        districtDelete(record.district_id, accessToken)
        getStatesDetails()
        getStatesDetails()
    }
    const assemblyConstituenciesDeleteRow = (record) => {
        deleteAssemblyConstituencies(
            record.assembly_constituency_id,
            accessToken
        )
        getStatesDetails()
        getStatesDetails()
    }
    const mandalDeleteRow = (record) => {
        deleteMandal(record.mandal_id, accessToken)
        getStatesDetails()
    }
    const locationModal = (
        <Modal
            open={showRoleModal}
            title="Add New"
            onCancel={() => setShowRoleModal(false)}
            footer={[
                <Button
                    form="roleForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Add
                </Button>,
            ]}
        >
            <AddLocation
                selectedLocation={selectedLocation}
                selectedStateKey={selectedStateKey}
                selectedAssemblyConstituencyKey={
                    selectedAssemblyConstituencyKey
                }
                selectedDistrictKey={selectedDistrictKey}
                selectedMandalKey={selectedMandalKey}
                selectedRole={false}
                getTableData={getStatesDetails}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
            />
        </Modal>
    )
    const updateLocationModal = (
        <Modal
            open={showRoleModal}
            title="Update"
            onCancel={() => setShowRoleModal(false)}
            footer={[
                <Button
                    form="roleForm"
                    key="submit"
                    htmlType="submit"
                    type="primary"
                >
                    Update
                </Button>,
            ]}
        >
            <EditLocation
                selectedLocation={selectedLocation}
                selectedStateKey={selectedStateKey}
                selectedRecord={selectedRecord}
                selectedAssemblyConstituencyKey={
                    selectedAssemblyConstituencyKey
                }
                selectedDistrictKey={selectedDistrictKey}
                selectedMandalKey={selectedMandalKey}
                selectedRole={false}
                getTableData={getStatesDetails}
                setShowModal={setShowRoleModal}
                accessToken={accessToken}
            />
        </Modal>
    )
    const modalContent = isUpdateMode ? updateLocationModal : locationModal
    return (
        <div className="report-container">
            <div className="content-header">
                <h2>Manage Locations</h2>
            </div>
            <div className="table-list">
                <CommonTable
                    columns={stateTableColumns}
                    data={statesData}
                    dataLoading={dataLoading}
                    rowClassName={stateClassName}
                    onRow={(record) => ({
                        onClick: () => onStateSelectRow(record),
                    })}
                />
                <CommonTable
                    columns={assemblyConstituenciesTableColumns}
                    data={assemblyconstituenciesData}
                    dataLoading={dataLoading}
                    rowClassName={assemblyConstituenciesClassName}
                    onRow={(record) => ({
                        onClick: () => onAssemblySelectRow(record),
                    })}
                />

                <CommonTable
                    columns={districtTableColumns}
                    data={districtsData}
                    dataLoading={dataLoading}
                    rowClassName={districtClassName}
                    onRow={(record) => ({
                        onClick: () => onDistrictSelectRow(record),
                    })}
                />
                <CommonTable
                    columns={mandalTableColumns}
                    data={mandalsData}
                    dataLoading={dataLoading}
                    rowClassName={mandalClassName}
                    onRow={(record) => ({
                        onClick: () => onMandalSelectRow(record),
                    })}
                />
                <CommonTable
                    columns={areaTableColumns}
                    data={areasData}
                    dataLoading={dataLoading}
                />
            </div>
            {modalContent}
        </div>
    )
}

export default Locations
