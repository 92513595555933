import React, { useEffect, useState } from 'react';
import { Table, Button, Space } from 'antd';
import { deleteParties, getElectionStates } from '../../AadhanApi';


import dayjs from 'dayjs'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ElectionTable from '../ElectionCommonTable/ElectionTable';


import EditCandidates from './EditCandidates';


const CandidateTable = ({dataSource ,dataLoading,handleDelete,token,getCandidates}) => {
  
    const[initialData,setInitialData] = useState({});
    const[state,setState] = useState([]);
    const columns = [
        {
          title: 'Full Name',
          dataIndex: 'full_name',
          key: 'full_name',
        
        },
        {
          title: 'Education',
          dataIndex: 'education',
          key: 'education',
        
        },
        {
          title: 'Caste',
          dataIndex: 'caste',
          key: 'caste',
        },
        {
            title: 'Religion',
            dataIndex: 'religion',
            key: 'religion',
          },
         
          {
            title: 'Age',
            dataIndex: 'age',
            key: 'age',
          },
          {
            title: 'Gender',
            dataIndex: 'gender',
            key: 'gender',
          },
          {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
          },
          {
            title: 'State',
            dataIndex: 'state_name',
            key: 'state_name',
            render: (text,record) => <span>{state.filter((e)=>record.state_id===e.state_id).state_name}</span>,

          },
          {
            title: 'Profile',
            dataIndex: 'profile_pic',
            key: 'profile_pic',
            render: (text) => <img src={text} alt="Flag" style={{ width: 60, height: 60,borderRadius:"5px" }} />,
          },
          {
            title: 'Assets Value',
            dataIndex: 'assets_value',
            key: 'assets_value',
          },
          {
            title: 'Reservation Category',
            dataIndex: 'reservation_category',
            key: 'reservation_category',
          },
       
        {
          title: 'Edit / Delete',
          key: 'edit',
          render: (record) => (
            <Space>
              <Button onClick={()=>handleEdit(record)} type='danger' ><EditCandidates initialData={record} getCandidates={getCandidates} token={token}/></Button>
              <Button type="danger" onClick={() => handleDelete(record.candidate_id)}><DeleteOutlined/></Button>
            </Space>
          ),
        },
      ];
      function handleEdit(record){
        setInitialData(record)
      
      }
      async function getState(){
        const data = await getElectionStates(token)
        setState(data.data.data)
      }
      useEffect(()=>{
        getState()
      },[])
     
      
     
  return(
     <div> 
      <div style={{display:"flex",justifyContent:"end",padding:".4rem"}}> 
     
      </div> 
      <ElectionTable dataloading={dataLoading} dataSource={dataSource} electionStates={state} columns={columns} />
     </div>
  );
};

export default CandidateTable;

// 