import React, { useState, useEffect } from 'react'
import {
    Button,
    DatePicker,
    Form,
    Input,
    Modal,
    Select,
    Image,
    Row,
    Col,
} from 'antd'
import ImageUploader from '../image-uploader-crop/ImageUploader'
import {
    getElectionStates,
    getEelectionImageUrl,
    createPulsePolls,
    getElectionDistricts,
    getElectionDistrictsAssembly,
    getloksabhaData,
} from '../../AadhanApi'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import './AddPulsePollsConstant'
import { electionType } from './AddPulsePollsConstant'

const { Option } = Select

const AddPulsePollsForm = ({ token }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [form] = Form.useForm()
    const [locationsData, setLocationsData] = useState([])
    const [districtsData, setDistrictsData] = useState([])
    const [pollOptions, setPollOptions] = useState(1)
    const [pollType, setPollType] = useState(null)
    const [pollData, setPollData] = useState([])
    const [stateId, setStateId] = useState(null)
    const [districtCd, setDistrcitCd] = useState(null)
    const [electiontype, setElectionType] = useState(null)
    const [assemblyConstituencyId, setAssemblyConstituencyId] = useState(null)
    const [loksabhaConstituencyId, setLoksabhaConstituencyId] = useState(null)
    const [districtsAssemblyData, setDistrictsAssemblyData] = useState([])
    const [loksabhaData, setLoksabhaData] = useState([])
    // Fetch election states data on component mount
    useEffect(() => {
        getStatesList()
    }, [])
    // {stateId && getDistrictsList()}
    useEffect(() => {
        getDistrictsList()
    }, [stateId])
    useEffect(() => {
        getAssemblyConstituencies()
    }, [districtCd])
    useEffect(() => {
        getLoksabhaConstituencies()
    }, [electiontype])

    // Fetch election states list
    const getStatesList = async () => {
        try {
            const response = await getElectionStates(token)
            const statesData = response?.data?.data || []
            const formattedData = [
                { state_id: 37, state_name: 'India' },
                ...statesData,
            ]
            setLocationsData(formattedData)
        } catch (error) {
            console.error('Error fetching election states:', error)
        }
    }
    const getDistrictsList = async () => {
        try {
            const response = await getElectionDistricts(stateId, token)
            setDistrictsData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getLoksabhaConstituencies = async () => {
        try {
            const response = await getloksabhaData(stateId, electiontype)
            setLoksabhaData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }
    const getAssemblyConstituencies = async () => {
        try {
            const response = await getElectionDistrictsAssembly(
                districtCd,
                token
            )
            setDistrictsAssemblyData(response.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    // Handle state selection change
    const handleStateChange = (stateId) => {
        setStateId(stateId)
    }

    // Handle image upload and update pollData state
    const handleImageUpload = async (path, index) => {
        setIsLoading(true)
        try {
            const response = await getEelectionImageUrl(path, token)
            const imageUrl = response?.data?.url || ''
            setPollData((prevData) => {
                const updatedData = [...prevData]
                updatedData[index] = {
                    ...updatedData[index],
                    image: imageUrl,
                }
                return updatedData
            })
        } catch (error) {
            console.error('Error uploading image:', error)
        } finally {
            setIsLoading(false)
        }
    }

    // Handle text input change and update pollData state
    const handleTextChange = (value, index) => {
        setPollData((prevData) => {
            const updatedData = [...prevData]
            updatedData[index] = { ...updatedData[index], text: value }
            return updatedData
        })
    }

    // Handle form submission
    const handleFormSubmit = async () => {
        try {
            // Validate all poll data before submission
            const isValid = pollData.every((data) => data?.image && data?.text)
            if (!isValid) {
                console.error(
                    'Please upload all images and enter text for all poll options.'
                )
                return
            }

            // Create pulse with poll data
            const formData = {
                stateId: stateId,
                title: form.getFieldValue('title'),
                assembly_constituency_no: assemblyConstituencyId,
                lok_sabha_no: form.getFieldValue('lok_sabha_no'),
                election_type: electiontype,
                publishedDate: form.getFieldValue('publishedDate').unix(),
                options: pollData.map((data, index) => ({
                    ...data,
                    _id: index + 1,
                })),
                polltype: pollType,
            }
            await createPulsePolls(formData, token)
            form.resetFields()
            setStateId(null)
            setPollData([])
            setPollOptions(1)
            setIsLoading(false)
            setIsModalOpen(false)
            setDistrcitCd(null)
            setAssemblyConstituencyId(null)
            setLoksabhaConstituencyId(null)
        } catch (error) {
            console.error('Error creating pulse:', error)
        }
    }

    // Render multiple image uploaders and text inputs based on pollOptions
    const renderPollOptions = () => {
        const options = []
        for (let i = 0; i < pollOptions; i++) {
            options.push(
                <Row key={i} gutter={16} style={{ marginBottom: '16px' }}>
                    <Col span={12}>
                        <Form.Item
                            label={`Option ${i + 1}`}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: `Please upload image for Option ${
                                        i + 1
                                    }`,
                                },
                            ]}
                        >
                            <ImageUploader
                                getImagePath={(path) =>
                                    handleImageUpload(path, i)
                                }
                            />
                            {pollData[i]?.flagImage && (
                                <Image
                                    src={pollData[i]?.flagImage}
                                    alt="Flag Image"
                                    width={200}
                                    style={{
                                        padding: '1px',
                                        borderRadius: '5px',
                                        boxShadow: '2px 5px 5px gray',
                                        border: 'solid 1px gray',
                                        marginTop: '8px',
                                    }}
                                />
                            )}
                            {isLoading && <LoadingOutlined />}
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={`pollText_${i}`}
                            required
                            rules={[
                                {
                                    required: true,
                                    message: `Please enter text for Option ${
                                        i + 1
                                    }`,
                                },
                            ]}
                        >
                            <Input
                                placeholder={`Enter text for Option ${i + 1}`}
                                onChange={(e) =>
                                    handleTextChange(e.target.value, i)
                                }
                            />
                        </Form.Item>
                    </Col>
                </Row>
            )
        }
        return options
    }

    return (
        <>
            <Button
                type="primary"
                onClick={() => {
                    setIsModalOpen(true)
                    form.resetFields()
                }}
            >
                Add Pulse Polls
            </Button>
            <Modal
                title={<h5>Add Pulse Polls</h5>}
                onCancel={() => setIsModalOpen(false)}
                open={isModalOpen}
                footer={null}
                width={800}
            >
                <Form
                    form={form}
                    name="addPulseForm"
                    onFinish={handleFormSubmit}
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{ published_date: dayjs() }}
                >
                    <Form.Item
                        name="state_name"
                        label="Select State"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a state',
                            },
                        ]}
                    >
                        <Select
                            onChange={handleStateChange}
                            style={{ width: '100%' }}
                        >
                            {locationsData.map(({ state_id, state_name }) => (
                                <Option key={state_id} value={state_id}>
                                    {state_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="electionType"
                        label="Election Type"
                        rules={[
                            {
                                required: true,
                                message: 'Please the election type',
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value) => setElectionType(value)}
                        >
                            {electionType.map((optionValue) => (
                                <Option
                                    key={optionValue.value}
                                    value={optionValue.value}
                                >
                                    {optionValue.label}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {stateId !== 37 &&
                        electiontype === 'assembly_elections' && (
                            <Form.Item
                                name="district_cd"
                                label="Select District"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please select a district',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(district_cd) =>
                                        setDistrcitCd(district_cd)
                                    }
                                >
                                    {districtsData.map((district) => (
                                        <Option
                                            key={district.district_cd}
                                            value={district.district_cd}
                                        >
                                            {district.district_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    {stateId !== 37 &&
                        electiontype === 'assembly_elections' && (
                            <Form.Item
                                name="assembly_constituency_no"
                                label="Select Assembly"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a assembly',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(constituency_no) =>
                                        setAssemblyConstituencyId(
                                            constituency_no
                                        )
                                    }
                                >
                                    {districtsAssemblyData.map((assembly) => (
                                        <Option
                                            key={
                                                assembly.assembly_constituency_no
                                            }
                                            value={
                                                assembly.assembly_constituency_no
                                            }
                                        >
                                            {
                                                assembly.assembly_constituency_name
                                            }
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    {stateId !== 37 &&
                        electiontype === 'loksabha_elections' && (
                            <Form.Item
                                name="lok_sabha_no"
                                label="Select Loksabha"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please select a loksabha',
                                    },
                                ]}
                            >
                                <Select
                                    style={{ width: '100%' }}
                                    onChange={(constituency_no) =>
                                        setLoksabhaConstituencyId(
                                            constituency_no
                                        )
                                    }
                                >
                                    {loksabhaData.map((loksabha) => (
                                        <Option
                                            key={loksabha.constituency_no}
                                            value={loksabha.constituency_no}
                                        >
                                            {loksabha.constituency_name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        )}
                    <Form.Item
                        name="title"
                        label="Poll Title"
                        rules={[
                            {
                                required: true,
                                message: 'Please enter a poll title',
                            },
                        ]}
                    >
                        <Input style={{ width: '100%' }} />
                    </Form.Item>
                    <Form.Item
                        name="pollTYPE"
                        label="Poll Type"
                        rules={[
                            {
                                required: true,
                                message: 'Please the poll type',
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value) => setPollType(value)}
                        >
                            {['restrict', 'general'].map((optionValue) => (
                                <Option key={optionValue} value={optionValue}>
                                    {optionValue}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="publishedDate"
                        label="Published Date"
                        rules={[
                            {
                                required: true,
                                message: 'Please select a published date',
                            },
                        ]}
                    >
                        <DatePicker showTime format="DD/MM/YYYY HH:mm:ss" />
                    </Form.Item>
                    <Form.Item
                        name="pollOptions"
                        label="Number of Options"
                        rules={[
                            {
                                required: true,
                                message: 'Please select number of options',
                            },
                        ]}
                    >
                        <Select
                            style={{ width: '100%' }}
                            onChange={(value) => setPollOptions(value)}
                        >
                            {[1, 2, 3, 4].map((optionValue) => (
                                <Option key={optionValue} value={optionValue}>
                                    {optionValue}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {renderPollOptions()}
                    <Form.Item wrapperCol={{ offset: 6, span: 18 }}>
                        <Button type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default AddPulsePollsForm
