import React, { useEffect, useState } from 'react'
import { getElections } from '../../AadhanApi';
import MyCard from './MyCard';
import MapCandidates from './MapCandidates';


const ListElections = () => {
  const [listElections,setListElections]  =useState([]);
  const [election_id,setElection_id] = useState(undefined)
  const [isClicked,setIsClicked]=useState(false);
  const [titlt,setTitle] = useState("");
  const token = localStorage.getItem('ACCESS_TOKEN')
  async function getElectionsList(){
    const data =await getElections(token)
    
    setListElections(data.data.data)
  }
  useEffect(()=>{
   getElectionsList()
  },[])
  return (
    <div>
      {!isClicked ? 
    <div>
    <h3>List Elections</h3>
    <div>
      <h6 style={{backgroundColor:"lightGray",padding:".5rem"}}>Loksabha Elections 2024</h6>
     <div style={{display:"flex",flexWrap:"wrap",padding:"1rem",justifyContent:"space-between"}}>
     {listElections.map((e)=>{
        if(e.election_type==='loksabha_elections'){
             return   <MyCard setTitle={setTitle} setElection_id={setElection_id} id={e.election_id} setIsClicked={setIsClicked} title={e.election_type} start={e.start_date} end={e.end_date} result={""} phases={e.election_data['phases'].length}/>
        }
      })}
     </div>
    
    </div>
    <div>
      <h6 style={{backgroundColor:"lightGray",padding:".5rem"}}>Assembly Elections 2024</h6>
      <div style={{display:"flex",flexWrap:"wrap",padding:"1rem",justifyContent:"space-between"}}>
      {listElections.map((e)=>{
        if(e.election_type==='assembly_elections'){
             return   <MyCard setTitle={setTitle}  setElection_id={setElection_id} id={e.election_id} setIsClicked={setIsClicked} title={e.election_type} start={e.start_date} end={e.end_date} result={""}/>
        }
      })}
    </div>
    </div>
    <div>
      <h6 style={{backgroundColor:"lightGray",padding:".5rem"}}>Loksabha Bye Elections 2024</h6>
      <div style={{display:"flex",flexWrap:"wrap",padding:"1rem",justifyContent:"space-between"}}>
      {listElections.map((e)=>{
        if(e.election_type==='loksabha_bye_ elections'){
             return   <MyCard setTitle={setTitle}  setElection_id={setElection_id} id={e.election_id} setIsClicked={setIsClicked} title={e.election_type} start={e.start_date} end={e.end_date} result={""}/>
        }
      })}
      </div>
    </div>
    <div>
      <h6 style={{backgroundColor:"lightGray",padding:".5rem"}}>Assembly Bye Elections 2024</h6>
      
      <div style={{display:"flex",flexWrap:"wrap",padding:"1rem",justifyContent:"space-between"}}>
      {listElections.map((e)=>{
        if(e.election_type==='assembly_bye_elections'){
             return   <MyCard setTitle={setTitle}  setElection_id={setElection_id} id={e.election_id} title={e.election_type} start={e.start_date} end={e.end_date} result={""} setIsClicked={setIsClicked}/>
        }
      })}
      </div>
    </div>
  </div>:
  <div><h3>Create {titlt} 2024</h3>
  <MapCandidates election_id={election_id} title={titlt} token={token} setIsClicked={setIsClicked}/> 
  </div> 
  
    }
    </div>
  )
}

export default ListElections
