import React, { useEffect, useState } from 'react';
import { Table, Button, Space } from 'antd';
import { deleteParties, getAllianceForParties, getElectionStates } from '../../AadhanApi';
import AddParties from './AddPartiesForm';
import AddPartiesForm from './AddPartiesForm';
import EditPartiesForm from './EditPartiesForm';
import dayjs from 'dayjs'
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import ElectionTable from '../ElectionCommonTable/ElectionTable';
import { all } from 'axios';


const ManagePartiesTable = ({dataSource,handleDelete,token,getElectionParties,searchTerm,handleChange}) => {
    const[initialData,setInitialData] = useState({});
    const[state,setState] = useState([]);
    const [alliance,setAlliance] = useState([]);

    const columns = [
        {
          title: 'Flag',
          dataIndex: 'party_flag_img',
          key: 'flag',
render: (text) => <img src={text} alt="Flag" style={{ width: 60, height: 60,borderRadius:"5px" }} />,
        },          
        {
          title: 'Party Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Abbreviation',
          dataIndex: 'abbreviation',
          key: 'abbreviation',
        },
        {
          title: 'Symbol',
          dataIndex: 'election_symbol',
          key: 'election_symbol',
        },
        {
          title: 'Symbol Image',
          dataIndex: 'election_symbol_img',
          key: 'election_symbol_img',
          render: (text) => <img src={text} alt="Flag" style={{ width: 60, height: 60,borderRadius:"5px" }} />,
        },
        {
          title: 'Status',
          dataIndex: 'party_status',
          key: 'party_status',
        },
        {
          title: 'Established On',
          dataIndex: 'established_on',
          key: 'established_on',
          render: (_,record) => (
            
            <span>{ dayjs(record.established_on).format('YYYY-MM-DD')}</span>
          ),
        },
        {
          title: 'Founder',
          dataIndex: 'founder',
          key: 'founder',
        },
        {
          title: 'Edit / Delete',
          key: 'edit',
          render: (record) => (
            <Space>
              <Button type='danger' ><EditPartiesForm alliance={alliance} initialData={record} getElectionParties={getElectionParties} token={token}/></Button>
              <Button type="danger" onClick={() => handleDelete(record.party_id)}><DeleteOutlined/></Button>
            </Space>
          ),
        },
      ];
      function handleEdit(record){
        setInitialData(record)
      
      }
      // async function getState(){
      //   const data = await getElectionStates(token)
      //   setState(data.data.data)
      // }
      async function getAlliances(){
        const data = await getAllianceForParties(token)
        setAlliance([...data.data.data,{display_name:"none",alliance_id:0}])
      }
      useEffect(()=>{
        getAlliances()
      },[])
     
      
     
  return(
     <div> 
      <div style={{display:"flex",justifyContent:"end",padding:".4rem"}}> 
      <input
        type="text"
        
        placeholder="Search Party..."
        value={searchTerm}
        onChange={handleChange}
        style={{border:"solid lightgray 1px",borderRadius:"5px",outline:"none",marginRight:"2rem"}}
      />
      <AddPartiesForm alliance={alliance} token={token}  getElectionParties={ getElectionParties} />
      </div> 
      <ElectionTable dataSource={dataSource} columns={columns} />
     </div>
  );
};

export default ManagePartiesTable;
