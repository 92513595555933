import React, { useState } from 'react'
import { Button, DatePicker, Form, Image, Input, Modal, Select } from 'antd'
import {
    createElectionParties,
    getEelectionImageUrl,
    getElectionStates,
    getImageUrl,
} from '../../AadhanApi'
import { Option } from 'antd/es/mentions'
import ImageUploader from '../image-uploader-crop/ImageUploader'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
const AddPartiesForm = ({token,getElectionParties,alliance }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [flag, setFlag] = useState()
    const [symbol, setSymbol] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const [stateId, setStateId] = useState(null)
    const showModal = () => {
        setIsModalOpen(true)
        
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    
    function createRequest(values) {
        const formattedDate = dayjs(values.established_on).format('YYYY-MM-DD')
        // const foundState = electionStates.find(state => state.state_name === values.state_name);
        let reqAlliance = alliance.filter((e)=>e.display_name===values.alliance_name)
        reqAlliance = reqAlliance[0]
        
        return {
            abbreviation: values.abbreviation,
            election_symbol: values.election_symbol,
            established_on: formattedDate,
            founder: values.founder,
            name: values.name,
            party_status: values.party_status,
            party_flag_img: flag,
            election_symbol_img: symbol,
            alliance:(values.alliance_name!=='none' && values.alliance_name!==undefined) ? {name:reqAlliance.name, alliance_id:reqAlliance.alliance_id,display_name:reqAlliance.display_name,abbreviation:reqAlliance.abbreviation} : null
            // contests_from: [
            //     {
            //         state_id: foundState ? foundState?.state_id :null,
            //         state_cd: foundState ? foundState?.state_cd :null,
            //         state_name: values.state_name,
            //     },
            // ],
        }
    }
    async function handleFormSubmit(values) {
        const req = createRequest(values)
        const res = await createElectionParties(req, token)
        form.resetFields()
        setFlag()
        setSymbol()
        handleOk();
        getElectionParties()
        
    }

    const getImagePathFlag = async (path, type) => {
        setFlag('')
        setIsLoading(true)
        const pathUri = await getEelectionImageUrl(path, token)
        setFlag(pathUri.data.url)
        setIsLoading(false)
    }
    const getImagePathSymbol = async (path, type) => {
        setSymbol('')
        setIsLoading(true)
        const pathUri = await getEelectionImageUrl(path, token)
        setSymbol(pathUri.data.url)
        setIsLoading(false)
    }
    

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Add Parties
            </Button>
            <Modal
                title={<h5>Add Parties</h5>}
                closable={true}
                footer={false}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
            >
             <hr></hr>   
                <Form
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    onFinish={handleFormSubmit}
                    form={form}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter name',
                            },
                        ]}
                        name="name"
                        label="Name"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter abbreviation',
                            },
                        ]}
                        name="abbreviation"
                        label="Abbreviation"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: flag ? false : true ,
                                message:flag ?'' : 'Please upload flag_img',
                            },
                        ]}
                        name="party_flag_img"
                        label="Flag Image"
                    >
                        {flag && (
                            <Image
                                style={{
                                    padding: '1px',
                                    borderRadius: '5px',
                                    boxShadow: '2px 5px 5px gray',
                                    border: 'solid 1px gray',
                                }}
                                width={200}
                                src={flag}
                                alt="Uploaded Image"
                            />
                        )}
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                marginTop: '1rem',
                            }}
                        >
                            <ImageUploader  getImagePath={getImagePathFlag} />
                        </div>
                        <p>
                            {isLoading && (
                                <span>
                                    Uploading... <LoadingOutlined />
                                </span>
                            )}{' '}
                        </p>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter symbol',
                            },
                        ]}
                        name="election_symbol"
                        label="Election Symbol"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required:symbol ? false : true,
                                message: symbol ? '' :'Please upload symbol_img',
                            },
                        ]}
                        name="election_symbol_img"
                        label="Symbol Image"
                    >
                        {symbol && (
                            <Image
                                style={{
                                    padding: '1px',
                                    borderRadius: '5px',
                                    boxShadow: '2px 5px 5px gray',
                                    border: 'solid 1px gray',
                                }}
                                width={200}
                                src={symbol}
                                alt="Uploaded Image"
                            />
                        )}
                        <div
                            style={{
                                display: 'flex',
                                gap: '5px',
                                marginTop: '1rem',
                            }}
                        >
                            <ImageUploader getImagePath={getImagePathSymbol} />
                        </div>
                        <p>
                            {isLoading && (
                                <span>
                                    Uploading... <LoadingOutlined />
                                </span>
                            )}{' '}
                        </p>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter status',
                            },
                        ]}
                        name="party_status"
                        initialValue={'national'}
                        label="Party Status"
                    >
                        <Input
                            style={{ width: 250 }}
                            value={'national'}
                        
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please select date',
                            },
                        ]}
                        name="established_on"
                        label="Established On"
                    >
                        <DatePicker
                            format={{
                                format: 'YYYY-MM-DD',
                                type: 'mask',
                            }}
                            style={{ width: 200 }}
                        />
                    </Form.Item>
                    <Form.Item
                        
                        name="alliance_name"
                        label="Alliance"
                    >
                        <Select
                            
                            placeholder="-select Alliance-"
                            style={{ width: 200 }}
                        >
                            {alliance?.map((e) => (
                                <Option value={e.display_name} key={e.alliance_id}>
                                    {e.display_name}
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter founder name',
                            },
                        ]}
                        name="founder"
                        label="Founder Name"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ display: 'block', margin: '0 auto' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default AddPartiesForm
