import React, { useEffect, useState } from 'react'
import { Table, Button, Space } from 'antd'
import AddPulseForm from './AddPulseForm'
import { DeleteOutlined } from '@ant-design/icons'
import ElectionTable from '../ElectionCommonTable/ElectionTable'
import { getStateParties, deletePulse, getPulse } from '../../AadhanApi'
import { EditPulseForm } from './EditPulseForm'

const ManagePulseTable = ({
    dataSource,
    stateId,
    token,
    pulse,
    handleDelete,
}) => {
    const [initialData, setInitialData] = useState({})
    const [parties, setParties] = useState([])

    async function getParties() {
        if(stateId!=='' && stateId!==undefined){

        
        const data = await getStateParties(token, stateId)
    
        const partyNames = data?.data?.map((p) => p?.['name'])
        setParties(partyNames)
        }
    }
    useEffect(() => {
        getParties()
    }, [stateId])

    const columns = [
        {
            title: 'Event Name',
            dataIndex: 'Event_Title',
            key: 'Event_Title',
        },
        {
            title: 'Event Date',
            dataIndex: 'Event_date',
            key: 'Event_date',
            render: (text, record) => {
                const date = new Date(record?.Event_date * 1000)
                const year = date.getFullYear()
                const month = date.getMonth() + 1
                const day = date.getDate()
                const hours = date.getHours()
                const minutes = date.getMinutes()
                const seconds = date.getSeconds()
                return `${year}-${month < 10 ? '0' : ''}${month}-${
                    day < 10 ? '0' : ''
                }${day} ${hours}:${minutes}:${seconds}`
            },
        },
        {
            title: 'Total Seats',
            dataIndex: 'Total_votes',
            key: 'Total_votes',
        },
        {
            title: 'Election Type',
            dataIndex: 'Election_Type',
            key: 'Election_Type',
        },
    ]
    const party = []
    parties.map((partyInfo) => {
        party.push({
            title: partyInfo,
            dataIndex: partyInfo,
            key: partyInfo.toLowerCase(),
        })
    })
    party.push({
        title: 'Edit / Delete',
        key: 'edit',
        render: (text, record) => (
            <Space>
                <Button onClick={() => handleEdit(record)} type="danger">
                    <EditPulseForm
                        initialData={initialData}
                        party={getParties}
                        pulse={pulse}
                        token={token}
                        partyinfo={parties}
                    />
                </Button>
                <Button
                    type="danger"
                    onClick={() => handleDelete(record.Event_Title)}
                >
                    <DeleteOutlined />
                </Button>
            </Space>
        ),
    })
    const updatedColumns = columns.concat(party)
    function handleEdit(record) {
        setInitialData(record)
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'end',
                    padding: '.4rem',
                }}
            >
                <AddPulseForm token={token} pulse={pulse} />
            </div>
            <ElectionTable dataSource={dataSource} columns={updatedColumns} />
        </div>
    )
}

export default ManagePulseTable
