import { Option } from 'antd/es/mentions'
import React, { useEffect, useState } from 'react'
import {
    createExitPolls,
    createResults,
    editExitPolls,
    editResults,
    getElectionStates,
    getMedia,
} from '../../AadhanApi'
import { DeleteOutlined, EditOutlined } from '@ant-design/icons'
import {
    Table,
    Button,
    Space,
    Popconfirm,
    Form,
    Input,
    Modal,
    Select,
} from 'antd'
import AddModal from './AddModal'
import EditModal from './EditModal'

const AddExitPollsTable = ({ data, getFetchData, title, type, source }) => {
    const [electionType, setElectionType] = useState(type)
    const [selectedState, setSelectedState] = useState('')
    const [selectedMedia, setSelectedMedia] = useState(
        title !== 'add' ? source?.mediaSource : ''
    )
    const [year, setYear] = useState(new Date().getFullYear())
    const [state, setState] = useState([])
    const [media, setMedia] = useState([])
    const token = localStorage.getItem('ACCESS_TOKEN')
    const currentYear = new Date().getFullYear()
    const [dataLoading, setDataLoading] = useState(false)
    const years = Array.from({ length: 6 }, (_, index) => currentYear + index)
    const [initialData, setInitialData] = useState([])
    const [isModified, setIsModified] = useState(true)
    const [resultId, setResultId] = useState(undefined)

    useEffect(() => {
        setSelectedState(title !== 'add' ? source.state : state[0]?.state_name)
        setSelectedMedia(
            title !== 'add' ? source?.mediaSource : media[0]?.display_name
        )
    }, [source])
    function handleDelete(index) {
        setIsModified(false)
        const temp = initialData?.filter((e, ind) => ind !== index)
        setInitialData(temp)
    }
    // useEffect(()=>{setSelectedMedia(source?.mediaSource)},[source])
    const columns = [
        {
            title: 'Party',
            dataIndex: 'party_name',
        },
        {
            title: 'Projected Seats',
            dataIndex: 'seats',
        },
        {
            title: 'Display Name',
            dataIndex: 'displayName',
        },

        {
            title: 'Actions',
            key: 'actions',
            render: (_, record, index) => (
                <Space size="middle">
                    <Button type="primary">
                        <EditModal
                            setIsModified={setIsModified}
                            type={'edit'}
                            initialData={initialData}
                            initialValues={record}
                            index={index}
                            setInitialData={setInitialData}
                        />
                    </Button>
                    <Button
                        onClick={() => handleDelete(index)}
                        icon={<DeleteOutlined />}
                    />
                </Space>
            ),
        },
    ]

    async function getDatafunc() {
        const data = await getElectionStates(token)
        setState([
            ...data.data.data,
            { state_name: 'India', state_id: -1, state_cd: -1 },
        ])
        setSelectedState(
            title !== 'add' ? source.state : data.data.data[0].state_name
        )
        const media = await getMedia(token)
        setMedia(media.data.data)
        setSelectedMedia(
            title !== 'add'
                ? source?.mediaSource
                : media.data.data[0].display_name
        )
    }

    function filterData() {
        const temp = data.filter(
            (e) =>
                e.election_type === electionType &&
                e.media_source?.display_name === selectedMedia &&
                e.state?.state_name === selectedState &&
                e.election_year === year
        )

        if (temp.length !== 0) {
            setResultId(temp[0].poll_id)
            const results = temp[0]?.projected_results?.map((e) => {
                return {
                    ...e,
                    party_name: e.party?.abbreviation,
                    displayName: e.party?.display_name,
                    projectedSeats: e.seats,
                    alliance:
                        e.party?.alliance !== undefined &&
                        e.party?.alliance !== null
                            ? e.party?.alliance
                            : null,
                }
            })
            setInitialData(results)
        } else {
            setResultId(undefined)
            setInitialData([])
        }
    }
    useEffect(() => {
        getDatafunc()
    }, [])
    useEffect(() => {
        setElectionType(type)
    }, [type])
    useEffect(() => {
        filterData()
    }, [data, selectedMedia, selectedState, year, electionType])
    async function updateResult(req) {
        const res = await editExitPolls(resultId, req, token)
        setIsModified(true)
        getFetchData()
    }
    async function createReq(req) {
        const res = await createExitPolls(req, token)
        setIsModified(true)
        getFetchData()
    }

    function handleSave() {
        const resMedia = media?.filter((e) => e.display_name === selectedMedia)
        const resState = state?.filter((e) => e.state_name === selectedState)

        const mediaSource = {
            media_id: resMedia[0].media_id,
            name: resMedia[0].name,
            display_name: resMedia[0].display_name,
        }
        const stateobj = {
            state_id: resState[0].state_id,
            state_cd: resState[0].state_cd,
            state_name: resState[0].state_name,
        }
        let Result = initialData.map((e) => {
            return {
                ...e,
                party: {
                    abbreviation: e.party?.abbreviation,
                    display_name: e.displayName,
                    name: e.party?.name,
                    party_flag_img: e.party?.party_flag_img,
                    party_id: e.party?.party_id,
                    alliance:
                        e.party?.alliance !== undefined &&
                        e.party?.alliance !== null
                            ? {
                                  alliance_id: e.party?.alliance?.alliance_id,
                                  display_name: e.party?.alliance?.display_name,
                                  abbreviation: e.party?.alliance?.abbreviation,
                              }
                            : null,
                    
                },
                
            }
            // alliance:{e.alliance}
        })
        Result = Result.map(
            ({ key, displayName, projectedSeats, party_name, ...rest }) => rest
        )
        if (resultId !== undefined) {
            const finalRes = {
                media_source: mediaSource,
                election_type: electionType,
                election_year: year,
                state: stateobj,
                result_id: resultId,
                projected_results: Result,
            }
            updateResult(finalRes)
        } else {
            const finalRes = {
                media_source: mediaSource,
                election_type: electionType,
                election_year: year,
                state: stateobj,
                projected_results: Result,
            }
            createReq(finalRes)
        }
    }
    return (
        <div>
            <div style={{ padding: '0 1rem' }}>
                <label style={{ margin: '0 1rem 0 0', fontWeight: 500 }}>
                    Type :{' '}
                </label>
                <Select
                    onChange={(e) => setElectionType(e)}
                    value={electionType}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                >
                    <Option value="loksabha_elections" key="loksabha_elections">
                        loksabha
                    </Option>
                    <Option value="assembly_elections" key="assembly_elections">
                        assembly
                    </Option>
                </Select>
                <label style={{ margin: '0 1rem 0 0', fontWeight: 500 }}>
                    Select State :{' '}
                </label>
                <Select
                    onChange={(e) => setSelectedState(e)}
                    value={selectedState}
                    style={{ width: 200, margin: '0 2rem 0 0' }}
                >
                    {state.map((e) => (
                        <Option value={e.state_name} key={e.state_id}>
                            {e.state_name}
                        </Option>
                    ))}
                </Select>
                <label style={{ margin: '0 1rem 0 0', fontWeight: 500 }}>
                    Year :{' '}
                </label>
                <Select
                    onChange={(e) => setYear(e)}
                    defaultValue={currentYear}
                    style={{ width: 120, margin: '0 2rem 0 0' }}
                >
                    {years.map((year) => (
                        <Option key={year} value={year}>
                            {year}
                        </Option>
                    ))}
                </Select>
            </div>
            <div style={{ padding: '1rem 0 0 1rem' }}>
                <label style={{ margin: '0 1rem 0 0', fontWeight: 500 }}>
                    Media Source :{' '}
                </label>
                <Select
                    onChange={(e) => setSelectedMedia(e)}
                    value={selectedMedia}
                    style={{ width: 200 }}
                >
                    {media.map((e) => (
                        <Option value={e.display_name} key={e.media_id}>
                            {e.display_name}
                        </Option>
                    ))}
                </Select>
            </div>
            {
                <Button
                    type="primary"
                    style={{ display: 'block', marginLeft: 'auto' }}
                >
                    <AddModal
                        setIsModified={setIsModified}
                        type={'Add'}
                        initialData={initialData}
                        setInitialData={setInitialData}
                    />
                </Button>
            }
            <Table dataSource={initialData} columns={columns} />
            {isModified && 'Every thing is upto date'}
            <Button
                type="primary"
                onClick={() => handleSave()}
                disabled={isModified}
                style={{
                    display: 'block',
                    margin: '0 auto',
                    padding: '0 3rem',
                }}
            >
                {title === 'add' ? 'save' : 'update'}
            </Button>
        </div>
    )
}

export default AddExitPollsTable
