import { Table } from 'antd'
import React, { useEffect, useState } from 'react'
import './CommonTable.scss'
import Link from 'antd/es/typography/Link'

const CommonTable = ({ pageSize, columns, data, dataLoading,rowClassName, disablePagination, onRow }) => {
    const [inputColumns, setInputColums] = useState([...columns])
    const [paginationOptions,setpaginationOptions] = useState({
       
            pageSizeOptions: ['2','7', '20', '25', '30',],
            showSizeChanger: true,           
            current:1
        
    })
    const handleOnchange = (pagination, filters, sorter) => {
           
        setpaginationOptions({...pagination})
        if (sorter.field && sorter.order) {
            setInputColums((prevState) => {
            
                const sortedColumn = prevState.find(
                    (col) => col.dataIndex === sorter.field
                )
                sortedColumn.sortOrder = sorter.order
                return [...prevState]
            })
        }
    }
    // const rowClassName = (record, index) => {
    //     return {
    //         backgroundColor: index % 2 === 0 ? '#f5f5f5' : '#ffffff',
    //     };
    // };
    


    useEffect(() => {
        setInputColums([...columns])
    }, [columns])

    

    useEffect(()=>{
        setpaginationOptions({...paginationOptions,current:1})
        
    },[data])

    return (
        <div className="table-container">
            <Table
                
                pagination={disablePagination ? false : paginationOptions}
                scroll={{ x: '100%' }}
                columns={inputColumns}
                dataSource={data}
                loading={dataLoading}
                onChange={handleOnchange}
                rowClassName={rowClassName}
                onRow={onRow}
                
                
            />
        </div>
    )
}
export default CommonTable
