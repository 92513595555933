export const electionType = [
    {
        label: 'Assembly',
        value: 'assembly_elections',
    },
    {
        label: 'Loksabha',
        value: 'loksabha_elections',
    },
    {
      label: 'Assemly By Poll',
      value: 'assembly_bye_elections'
    },
    {
      label: 'Loksabha By Poll',
      value: 'loksabha_bye_elections'
    }
]
