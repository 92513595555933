import React, { useState, useEffect } from 'react'
import {
    Button,
    DatePicker,
    Form,
    Input,
    Modal,
    Select,
    InputNumber,
} from 'antd'

import {
    getStateParties,
    getElectionStates,
    createPulse,
} from '../../AadhanApi'
import dayjs from 'dayjs'
import { electionType } from './AddPulseConstant'
import FormItem from 'antd/es/form/FormItem'

const AddPulseForm = ({ token, pulse }) => {
    const accessToken = localStorage.getItem('ACCESS_TOKEN')
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [form] = Form.useForm()
    const [locationsData, setLocationsData] = useState([])
    const [parties, setParties] = useState([])
    const [flag, setFlag] = useState()
    const [symbol, setSymbol] = useState()
    const [stateId, setStateId] = useState('')
    const showModal = () => {
        setIsModalOpen(true)
    }
    const handleOk = () => {
        setIsModalOpen(false)
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }
    function handleStateChange(e) {
        setStateId(e)
    }
    async function getParties() {
        if(stateId!==''){
            const data = await getStateParties(token, stateId)
            const partyNames = data?.data?.map((p) => ({
                name: p?.name,
                id: p?.id,
            }))
            setParties(partyNames)
        }
        
    }
    const getStatesList = async () => {
        const response = await getElectionStates(accessToken).then((res) => {
            if (res?.data.data) {
                const data = [
                    { state_id: 37, state_name: 'India' },
                    ...res.data.data,
                ]
                setLocationsData(data)
            }
        })
    }
    useEffect(() => {
        getStatesList()
    }, [])

    useEffect(() => {
        getParties()
    }, [stateId])

    function createRequest(values) {
        const formattedDate = dayjs(values.event_date).unix()
        const {
            election_type,
            event_name,
            event_date,
            state_name,
            total_seats,
            ...partiesGrp
        } = values
        const formatedPartiesGrp = {}
        Object?.entries(partiesGrp)?.forEach(([k, v]) => {
            formatedPartiesGrp[k] = { partywise_votes: parseInt(v) }
        })
        return {
            stateId: stateId,
            Total_votes: values.total_seats,
            Event_Title: values.event_name,
            Election_Type: values.election_type,
            Event_date: formattedDate,
            parties: formatedPartiesGrp,
        }
    }
    async function handleFormSubmit(values) {
        const req = createRequest(values)
        const res = await createPulse(req, token)
        form.resetFields()
        pulse()
        setFlag()
        setSymbol()
        handleOk()
    }

    return (
        <>
            <Button type="primary" onClick={showModal}>
                Add Pulse
            </Button>
            <Modal
                title={<h5>Add Pulse</h5>}
                closable={false}
                footer={false}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                width={700}
            >
                <hr></hr>
                <Form
                    name="basic"
                    colon={false}
                    scrollToFirstError
                    labelWrap
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 18 }}
                    // initialValues={{ total_seats: initialTotalSeats }}
                    onFinish={handleFormSubmit}
                    form={form}
                >
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please select state',
                            },
                        ]}
                        name="state_name"
                        label="Select State"
                    >
                        <Select
                            onChange={(e) => {
                                handleStateChange(e)
                            }}
                            // defaultValue={'Andhra Pradesh'}
                            style={{ width: '10rem', height: '2rem' }}
                        >
                            {locationsData.map((e) => {
                                return (
                                    <Select.Option
                                        value={e.state_id}
                                        key={e.state_id}
                                    >
                                        {e.state_name}
                                    </Select.Option>
                                )
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Please enter event name',
                            },
                        ]}
                        name="event_name"
                        label="Event Name"
                    >
                        <Input style={{ width: 250 }} />
                    </Form.Item>
                    <Form.Item label="Event Date" name="event_date">
                        <DatePicker
                            showTime
                            format="DD/MM/YYYY h:mm A"
                            
                        />
                    </Form.Item>
                    <FormItem name="total_seats" label="Total Seats">
                        <InputNumber style={{ width: 250 }} placeholder="23" />
                    </FormItem>
                    {parties.map((partyName, index) => (
                        <Form.Item
                            key={partyName?.id}
                            name={partyName?.id}
                            label={partyName?.name}
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter the seats',
                                },
                            ]}
                        >
                            <Input
                                style={{ width: 200 }}
                                placeholder={`Enter seats for ${partyName?.name}`}
                                value={parseInt(0)}
                            />
                        </Form.Item>
                    ))}
                    <Form.Item
                        name="election_type"
                        label="Election Type"
                        rules={[{ required: true }]}
                    >
                        <Select style={{ width: 200 }}>
                            {electionType.map((option) => (
                                <Select.Option
                                    key={option.value}
                                    value={option.value}
                                >
                                    {option.label}
                                </Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{ display: 'block', margin: '0 auto' }}
                            type="primary"
                            htmlType="submit"
                        >
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}
export default AddPulseForm
