import {
    Button,
    Col,
    Form,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Tabs,
} from 'antd'
import { useSelector } from 'react-redux'
import {
    getReporterDetails,
    getStates,
    updateReporter,
    getDistricts,
    getMandals,
    getAssemblyConstituenciesReporter,
    getElectionConstituencies,
} from '../../AadhanApi'
import { useLocation, useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import './Reporter.scss'
import { current } from '@reduxjs/toolkit'
const { TabPane } = Tabs
const EditEmployee = () => {
    const paymentTermOptions = [
        {
            value: 'monthly',
            label: 'Monthly',
        },
        {
            value: 'weekly',
            label: 'Weekly',
        },
        {
            value: 'yearly',
            label: 'Yearly',
        },
    ]
    const [form] = Form.useForm()
    const [selectedEmployee, setSelectedEmployee] = useState()
    const navigate = useNavigate()
    const location = useLocation()
    const searchparam = new URLSearchParams(location?.search)
    const contentId = searchparam.get('reporterId')
    const accessToken = useSelector((state) => state.app.accessToken)
    const languageData = useSelector((state) => state.app.languageData)
    const [locationsData, setLocationsData] = useState([])
    const [
        selectedPrimaryConstituencyName,
        setSelectedPrimaryConstituencyName,
    ] = useState(null)
    const [districtsData, setDistrictsData] = useState([])
    const [selectedMappingId, setSelectedMappingId] = useState(null)
    const [electionAssemblyData, setElectionAssemblyData] = useState([])
    const [mandalsData, setMandalsData] = useState([])
    const [assemblyData, setAssemblyData] = useState([])
    const [selectedDistrictNames, setSelectedDistrictNames] = useState('')
    const [selectedStateNames, setSelectedStateNames] = useState('')
    const [selectedMappingStateId, setSelectedMappingStateId] = useState(null)
    const [selectedMandalNames, setSelectedMandalNames] = useState('')
    const [selectedConstituencyNames, setSelectedConstituencyNames] = useState(
        []
    )
    const [selectedDistric_Id, setSelectDistric_Id] = useState([])
    const [preselectedDistric_Id, presetSelectDistric_Id] = useState([])
    const onFinish = async (values) => {
        let districIDs = []
        if (values?.district) {
            for (let i = 0; i < values?.district.length; i++) {
                for (let j = 0; j < districtsData.length; j++) {
                    if (districtsData[j].name === values?.district[i]) {
                        districIDs.push(districtsData[j].district_id)
                    }
                }
            }
        }
        let mandalIDs = []
        if (values?.mandal) {
            for (let i = 0; i < values?.mandal.length; i++) {
                for (let j = 0; j < mandalsData.length; j++) {
                    if (mandalsData[j].name === values?.mandal[i]) {
                        mandalIDs.push(mandalsData[j].mandal_id)
                    }
                }
            }
        }

        const obj = {
            full_name: values.fullName,
            languages: values.languages ? values.languages : [],
            reporter_information: {
                compensation: values.compensation === 'active' ? true : false,
                per_image_article_price: values.costPerImageArticle,
                per_video_article_price: values.costPerVideoArticle,
                payment_term: values.paymentTerm,
                pen_name: values.penName,
                state_id: values.state || '',
                state: selectedStateNames || '',
                district_id:
                    districIDs?.filter((e) => e !== '').join(',') || '',
                district:
                    values.district?.filter((e) => e !== '').join(',') || '',
                mandal_id: mandalIDs?.filter((e) => e !== '').join(',') || '',
                mandal: values.mandal?.filter((e) => e !== '').join(',') || '',
                constituencies: selectedConstituencyNames || [''],
                constituency_ids: values.assemblyConstituency || [''],
                payment_info: {
                    account_number: values.accountNumber,
                    ifsc_code: values.ifscCode,
                    account_holder_name: values.accountHolderName,
                    gst_number: values.gstNumber,
                    pan_number: values.panNumber,
                },
            },
        }
        if (selectedPrimaryConstituencyName) {
            obj.reporter_information.primary_constituency_info = {
                constituency_id: values.primaryAssemblyConstituency ,
                constituency_name: selectedPrimaryConstituencyName ,
                mapping_id: values.electionAssemblyConstituency,
                mapping_state_id: selectedMappingStateId ,
            }
        }
        const stringerResponse = await updateReporter(
            obj,
            contentId,
            accessToken
        )
        if (stringerResponse.status == 200) {
            navigate('/home/stringers')
            form.resetFields()
        }
    }

    const updateFormData = async (contentId, accessToken) => {
        const response = await getReporterDetails(contentId, accessToken)
        setSelectedEmployee(response?.data)
        if (response?.data) {
            if (response?.data?.district_id !== '') {
                // setSelectDistric_Id([response?.data?.district_id])
            }
            form.setFieldsValue({
                fullName: response.data?.full_name,
                email: response.data?.email_id,
                phoneNumber: response.data?.mobile_number,
                penName: response.data?.reporter_information?.pen_name,
                languages: response.data?.languages,
                assemblyConstituency:
                    response.data?.reporter_information?.constituency_ids,
                compensation: response.data?.reporter_information?.compensation
                    ? 'active'
                    : 'inactive',
                paymentTerm: response.data?.reporter_information?.payment_term,
                accountNumber:
                    response.data?.reporter_information?.payment_info
                        ?.account_number,
                ifscCode:
                    response.data?.reporter_information?.payment_info
                        ?.ifsc_code,
                accountHolderName:
                    response.data?.reporter_information?.payment_info
                        ?.account_holder_name,
                panNumber:
                    response.data?.reporter_information?.payment_info
                        ?.pan_number,
                gstNumber:
                    response.data?.reporter_information?.payment_info
                        ?.gst_number,
                state: response.data?.reporter_information?.state_id,
                primaryAssemblyConstituency:
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.constituency_id,
                electionAssemblyConstituency:
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.mapping_id,
                district:
                    response.data?.reporter_information?.district?.split(','),
                mandal: response.data?.reporter_information?.mandal?.split(','),
                costPerImageArticle:
                    response.data?.reporter_information
                        ?.per_image_article_price,
                costPerVideoArticle:
                    response.data?.reporter_information
                        ?.per_video_article_price,
            })
            if (response.data?.reporter_information?.state) {
                getDistrictsList(response.data?.reporter_information?.state_id)
                getAssembly(response.data?.reporter_information?.state_id)
                setSelectedStateNames(
                    response.data?.reporter_information?.state
                )
            }
            if (response.data?.reporter_information?.district) {
                getMandalsList(response.data?.reporter_information?.district_id)

                setSelectedDistrictNames(
                    response.data?.reporter_information?.district
                )
            }
            if (response.data?.reporter_information?.mandal) {
                setSelectedMandalNames(
                    response.data?.reporter_information?.mandal
                )
            }
            if (response.data?.reporter_information?.constituencies) {
                setSelectedConstituencyNames(
                    response.data?.reporter_information?.constituencies
                )
            }
            if (
                response?.data?.reporter_information?.primary_constituency_info
                    ?.constituency_name
            ) {
                setSelectedPrimaryConstituencyName(
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.constituency_name
                )
            }
            if (
                response?.data?.reporter_information?.primary_constituency_info
                    ?.mapping_id
            ) {
                setSelectedMappingId(
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.mapping_id
                )
            }
            if (
                response?.data?.reporter_information?.primary_constituency_info
                    ?.mapping_state_id
            ) {
                setSelectedMappingStateId(
                    response?.data?.reporter_information
                        ?.primary_constituency_info?.mapping_state_id
                )
            }
        }
    }

    useEffect(() => {
        if (contentId) {
            getStatesList()
            getElectionAssemblyConstituencies()
            updateFormData(contentId, accessToken)
        }
    }, [contentId])

    const getStatesList = async () => {
        const response = await getStates(accessToken)
        setLocationsData(response.data)
    }
    const getElectionAssemblyConstituencies = async () => {
        const response = await getElectionConstituencies(
            'assembly_elections',
            1,
            accessToken
        )
        setElectionAssemblyData(response?.data?.data)
    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const onStateChange = (id) => {
        getDistrictsList(id)
        getAssembly(id)
        const selectedNames = locationsData
            .filter((option) => id.includes(option.state_id))
            .map((selectedOption) => selectedOption.name)
        setSelectedStateNames(selectedNames?.[0])
    }

    const getAssembly = async (id) => {
        const response = await getAssemblyConstituenciesReporter(
            id,
            accessToken
        )
        setAssemblyData(response.data)
    }

    const getDistrictsList = async (id) => {
        const response = await getDistricts(id, accessToken)
        setDistrictsData(response.data)
    }

    const onDistrictChange = (selectedDistrictIds) => {
        // getMandalsList(selectedDistrictIds)
        let temp = []
        districtsData.map((e) => {
            if (selectedDistrictIds.includes(e.name)) {
                temp.push(e.district_id)
            }
        })
        setSelectDistric_Id(temp)
        const selectedNames = districtsData
            .filter((option) => temp.includes(option.district_id))
            .map((option) => option.name)
            .join(',')
        const selectedIds = districtsData
            .filter((option) => temp.includes(option.district_id))
            .map((option) => option.district_id)
            .join(',')
        setSelectedDistrictNames(selectedNames)
        getMandalsList(selectedIds)
    }

    const getMandalsList = async (id) => {
        const mandalPromises = Array.isArray(id)
            ? id
            : id?.split(',')?.map(async (i) => {
                  const reponse = await getMandals(i, accessToken)
                  return await reponse?.data
              })
        const mandalDataArray = await Promise.all(mandalPromises)
        const totalMandalData = mandalDataArray.reduce((acc, curr) => {
            return [...acc, ...curr]
        })
        setMandalsData(totalMandalData)
    }
    const onMandalChange = (id) => {
        const selectedNames = mandalsData
            .filter((option) => id.includes(option.mandal_id))
            .map((selectedOption) => selectedOption.name)
        setSelectedMandalNames(selectedNames.join(','))
    }

    const onConstituencyChange = (id) => {
        const selectedNames = assemblyData
            .filter((option) => id.includes(option.assembly_constituency_id))
            .map((selectedOption) => selectedOption.name)
        setSelectedConstituencyNames(selectedNames)
    }
    const onPrimaryConstituencyChange = (id) => {
        if (id !== []) {
            const selectedPrimaryNames = assemblyData
                .filter((option) =>
                    id.includes(option.assembly_constituency_id)
                )
                .map((selectedOption) => selectedOption.name)
            setSelectedPrimaryConstituencyName(selectedPrimaryNames?.[0])
        } else {
            setSelectedPrimaryConstituencyName(null)
        }
    }
    const onElectionConstituencyChange = (id) => {
        if (id !== []) {
            const selectedElectionAssembly = electionAssemblyData
                .filter((option) => option.constituency_no === id)
                .map((selectedOption) => selectedOption.state_id)
            setSelectedMappingStateId(selectedElectionAssembly?.[0])
        } else {
            setSelectedMappingStateId(null)
        }
    }
    return (
        <div className="padding-20 edit-reporter">
            <h3 className="heading">Edit Stringer</h3>
            <div className="padding-20">
                <Form
                    id="form"
                    form={form}
                    name="employeeForm"
                    scrollToFirstError
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 18 }}
                    initialValues={{
                        compensation: 'active',
                        fullName: '',
                        lastName: '',
                        email: '',
                        mobile: '',
                        role: '',
                        languages: '',
                        password: '',
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row
                        style={{
                            marginBottom: '17px',
                            marginTop: '10px',
                        }}
                    >
                        <Col span={12}>
                            <Form.Item
                                label="Full Name"
                                name="fullName"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label="Email"
                                name="email"
                                validateTrigger="onBlur"
                            >
                                <Input disabled />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item
                                label="Pen Name"
                                name="penName"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Language(s)" name="languages">
                                <Select mode="multiple">
                                    {languageData.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.language_id}
                                        >
                                            {option.language_name}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item label="Phone Number" name="phoneNumber">
                                <Input disabled />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="state" label="State">
                                {locationsData && (
                                    <Select
                                        placeholder="Select State"
                                        onChange={onStateChange}
                                    >
                                        {locationsData?.map((option) => (
                                            <Select.Option
                                                key={option.state_id}
                                                value={option.state_id}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item label="District" name="district">
                                {districtsData && (
                                    <Select
                                        placeholder="Select District"
                                        onChange={onDistrictChange}
                                        mode="multiple"
                                    >
                                        {districtsData?.map((option) => (
                                            <Select.Option
                                                key={option.name}
                                                value={option.name}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Mandal" name="mandal">
                                {mandalsData && (
                                    <Select
                                        placeholder="Select Mandal"
                                        onChange={onMandalChange}
                                        mode="multiple"
                                    >
                                        {mandalsData?.map((option) => (
                                            <Select.Option
                                                key={option.name}
                                                value={option.name}
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Assembly
                                        <br />
                                        Constituency
                                    </div>
                                }
                                name="assemblyConstituency"
                            >
                                {assemblyData && (
                                    <Select
                                        placeholder="Select Assembly"
                                        onChange={onConstituencyChange}
                                        mode="multiple"
                                    >
                                        {assemblyData?.map((option) => (
                                            <Select.Option
                                                key={
                                                    option.assembly_constituency_id
                                                }
                                                value={
                                                    option.assembly_constituency_id
                                                }
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={<div>Compensation</div>}
                                name="compensation"
                            >
                                <Radio.Group>
                                    <Radio value="active"> Yes </Radio>
                                    <Radio value="inactive"> No </Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Primary <br /> Constituency
                                    </div>
                                }
                                name="primaryAssemblyConstituency"
                            >
                                {assemblyData && (
                                    <Select
                                        placeholder="Select Assembly"
                                        onChange={(value) =>
                                            onPrimaryConstituencyChange(
                                                value !== 'clear' ? value : []
                                            )
                                        }
                                    >
                                        <Select.Option
                                            key="clear"
                                            value="clear"
                                        >
                                            Clear Filter
                                        </Select.Option>
                                        {assemblyData.map((option) => (
                                            <Select.Option
                                                key={
                                                    option.assembly_constituency_id
                                                }
                                                value={
                                                    option.assembly_constituency_id
                                                }
                                            >
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Election <br /> Constituency
                                    </div>
                                }
                                name="electionAssemblyConstituency"
                            >
                                {electionAssemblyData && (
                                    <Select
                                        placeholder="Select Election Assembly"
                                        onChange={(value) =>
                                            onElectionConstituencyChange(
                                                value !== 'clear' ? value : []
                                            )
                                        }
                                    >
                                        <Select.Option
                                            key="clear"
                                            value="clear"
                                        >
                                            Clear Filter
                                        </Select.Option>
                                        {electionAssemblyData?.map((option) => (
                                            <Select.Option
                                                key={option.constituency_no}
                                                value={option.constituency_no}
                                            >
                                                {option.constituency_name}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row
                        style={{
                            marginBottom: '17px',
                            marginTop: '10px',
                        }}
                    >
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Cost per image
                                        <br />
                                        article
                                    </div>
                                }
                                name="costPerImageArticle"
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Cost per video
                                        <br />
                                        article
                                    </div>
                                }
                                name="costPerVideoArticle"
                                validateTrigger="onBlur"
                                rules={[
                                    {
                                        required: false,
                                        message: 'Please enter value',
                                    },
                                ]}
                            >
                                <InputNumber />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Account
                                        <br />
                                        Number
                                    </div>
                                }
                                name="accountNumber"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="IFSC Code" name="ifscCode">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item
                                label={
                                    <div>
                                        Account Holder
                                        <br />
                                        Name
                                    </div>
                                }
                                name="accountHolderName"
                            >
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="GST Number" name="gstNumber">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: '17px' }}>
                        <Col span={12}>
                            <Form.Item label="Payment Term" name="paymentTerm">
                                <Select>
                                    {paymentTermOptions.map((option, index) => (
                                        <Select.Option
                                            key={index}
                                            value={option.value}
                                        >
                                            {option.label}
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item label="Pan Number" name="panNumber">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        wrapperCol={{
                            span: 24,
                            offset: 0,
                            style: { textAlign: 'center', marginTop: '16px' },
                        }}
                    >
                        <Button
                            className="bg-color width-150"
                            type="primary"
                            htmlType="submit"
                        >
                            Save
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}
export default EditEmployee
