import React from 'react'
import { Route, Routes } from 'react-router'

import Dashboard from '../Dashboard/Dashboard'

import Home from '../Home/Home'
import Login from '../Login/Login'
import ManagePermissions from '../Permissions/ManagePermissions'
import ManageRoles from '../Permissions/ManageRoles'

import Forgotpassword from '../Login/ForgotPassword'

import EditReporter from '../Reporter/EditReporter'

import Locations from '../Locations/Locations'

import Master from '../Master/Master'

import AssemblyConstituency from '../AssemblyConstituency/AssemblyConstituency'
import AssemblyConstituencyDataEntry from '../AssemblyConstituencyDataEntry/AssemblyConstituencyDataEntry'
import CreateElection from '../CreateElection/CreateElection'
import MapPhases from '../CreateElection/MapPhases'
import ManageParties from '../ManageElectionParties/ManageParties'
import ManagePulse from '../ManagePulse/ManagePulse'
import ListElections from '../ListElections/ListElections'
import ManageMedia from '../ManageMedia/ManageMedia'
import ManageCandidate from '../ManageCandidate/ManageCandidate'

import ManagePulsePolls from '../ManagePulsePolls/ManagePulsePolls'
import Results from '../Results/Results'
import ConstituencyResult from '../ConstituencyResults/ConstituencyResult'
import ConstituencyResultsOverview from '../ConstituencyResultsOverview/ConstituencyResultsOverview'
import ManageExitPolls from '../ExitPolls/ManageExitPolls'

const AppRoute = () => {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/reset-password" element={<Forgotpassword />} />
            <Route path="/home" element={<Home />}>
                <Route
                    path="/home/assignRolesAndPermissions"
                    element={<ManagePermissions />}
                />
                <Route path="/home/" element={<Dashboard />} />
                <Route path="/home/ads" element={<Dashboard />} />

                <Route path="/home/manageRoles" element={<ManageRoles />} />

                <Route path="/home/locations" element={<Locations />} />

                <Route
                    path="/home/stringersContent"
                    element={<EditReporter />}
                />

                <Route path="/home/features" element={<Dashboard />} />

                <Route
                    path="/home/createElections"
                    element={<CreateElection />}
                />
                <Route path="/home/manageParties" element={<ManageParties />} />
                <Route
                    path="/home/listElections"
                    element={<ListElections />}
                ></Route>
                <Route
                    path="/home/manageMediaSources"
                    element={<ManageMedia />}
                ></Route>
                <Route
                    path="/home/manageCandidates"
                    element={<ManageCandidate />}
                ></Route>

                <Route path="/home/electionMaster" element={<Master />} />
                <Route path="/home/createElection" element={<Master />} />

                <Route
                    path="/home/mapPhases/phase/:val/:type"
                    element={<MapPhases />}
                />

                <Route
                    path="/home/constituencyMaster"
                    element={<AssemblyConstituency />}
                />
                <Route
                    path="/home/constituencyResults"
                    element={<AssemblyConstituencyDataEntry />}
                />

                <Route path="/home/pulsePolls" element={<ManagePulsePolls />} />
                <Route path="/home/pulse" element={<ManagePulse />} />
                <Route path="/home/exitpolls" element={<ManageExitPolls />} />
                <Route path="/home/results" element={<Results />} />
                <Route
                    path="/home/candidateMapping"
                    element={<ConstituencyResult />}
                />
                <Route
                    path="/home/constituencyResult"
                    element={<ConstituencyResultsOverview />}
                />
            </Route>
        </Routes>
    )
}
export default AppRoute
