import React from 'react'
import './Dashboard.scss'
// import ManageContent from '../ManageContent/ManageContent'

const Dashboard = ({ showAddButton = true }) => {
    return (
        <div className="dashboard-container">
            {/* <ManageContent showAddButton={showAddButton} /> */}
        </div>
    )
}
export default Dashboard
