import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import { getElectionStates, deletePulse, getPulse } from '../../AadhanApi'
import { useSelector } from 'react-redux'
import ManagePulseTable from './ManagePulseTable'

const ManagePulse = () => {
    const accessToken = useSelector((state) => state.app.accessToken)
    const [dataSource, setDataSource] = useState([])
    const { Option } = Select
    const [locationsData, setLocationsData] = useState([])
    const [selectedSateId, setSelectedSateId] = useState(37)
    const token = localStorage.getItem('ACCESS_TOKEN')
    useEffect(() => {
        getStatesList()
        setSelectedSateId(37)
    }, [])


    const getStatesList = async () => {
        const response = await getElectionStates(accessToken)
        const data = [
            { state_id: 37, state_name: 'India' },
            ...response.data.data,
        ]

        setLocationsData(data)
    }
    const handleStateChange = async (event) => {
        setSelectedSateId(event)
    }
    const getPulseData = async () => {
        if(selectedSateId!==''){

        
        const data = await getPulse(token, selectedSateId)
        
        setDataSource(data.data)
        }
    }
    useEffect(() => {
        getPulseData()
    }, [selectedSateId])

    const handleDelete = async (name) => {
        await deletePulse(token, name)
        getPulseData()
    }

    return (
        <div>
            <h2
                style={{
                    padding: '0 1rem .5rem 1rem',
                    borderBottom: 'solid 1px lightGray',
                }}
            >
                Manage Pulse
            </h2>
            <Select
                placeholder="Select State"
                onChange={(e) => handleStateChange(e)}
                defaultValue={37}
                style={{ width: 150, marginRight: '2rem' }}
            >
                {locationsData.map((option, index) => (
                    <Option key={option.state_id} value={option.state_id}>
                        {option.state_name}
                    </Option>
                ))}
            </Select>
            <ManagePulseTable
                dataSource={dataSource}
                stateId={selectedSateId}
                token={token}
                pulse={getPulseData}
                handleDelete={handleDelete}
            />
        </div>
    )
}

export default ManagePulse
