import { Button, Form, Input, Select, message } from 'antd';
import './Profile.css';
import blankProfile from './../../assets/blankProfile.png'
import { LockOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { updateEmployee, updatePassword } from '../../AadhanApi';
const Profile = ({ initialFormData,initialPasswordFormData, employeeId, token }) => {

    const [profileForm] = Form.useForm()
    const [updatePasswordForm] = Form.useForm()
    const [file, setFile] = useState();
    const [messageApi, contextHolder] = message.useMessage()    
    useEffect(() => {
        profileForm.setFieldsValue(initialFormData)
        updatePasswordForm.setFieldsValue(initialPasswordFormData)
    }, [profileForm, updatePasswordForm,initialFormData,initialPasswordFormData])

    useEffect(() => {       
        updatePasswordForm.setFieldsValue(initialPasswordFormData)
    }, [ updatePasswordForm,initialPasswordFormData])
    

    const onFinish = async (values) => {
        try {
            const request = {
                first_name: values?.firstName,
                last_name: values?.lastName,
                email_id: values?.email,
                mobile_number: values?.phone,
                role_id: initialFormData.roleId,
                languages: initialFormData?.languages,
                reports_to: initialFormData?.reportsTo,
                image: '',
                created_by: employeeId,
                created_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                typeof_employee: 'internal',
                last_login_time: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                updated_by: employeeId,
                updated_date: dayjs().format('YYYY-MM-DDTHH:mm:ss.SSSZ'),
                status: 'active'
            }
            //call update API        
            await updateEmployee(request, employeeId, token)

            messageApi.open({
                type: 'success',
                content: 'Employee is updated succesfully',
                duration: 5,
            })
        }
        catch (err) {
            console.log(err)
            messageApi.open({
                type: 'error',
                content: err?.response?.data?.detail,
                duration: 5,
            })
        }

    }

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo)
    }

    const onFileInputChange = (e) => {
        if (e?.target?.files && e?.target?.files?.length > 0) {
            setFile(e?.target?.files[0])
        }
    }
   
    const onFinishPassword = async (values) => {
       
        if(values?.newpassword!==values?.confirmpassword){
            messageApi.open({
                type: 'error',
                content: 'New password and Confrim password does not match',
                duration: 5,
            })
        }else{
            try{
                const response = await updatePassword(values?.oldpassword,values?.newpassword,token)
                messageApi.open({
                    type: 'success',
                    content: response?.data,
                    duration: 5,
                })
                updatePasswordForm.resetFields();
            }catch(err){
                updatePasswordForm.resetFields();
                console.log(err);
                messageApi.open({
                    type: 'error',
                    content: err?.response?.data?.detail,
                    duration: 5,
                })
            }
           
        }
    }

    return (
        <div className="container">
            {contextHolder}
            <div className="row">
                <div className="col-xl">
                    <div className="myprofile-container">
                        <h5>My Profile</h5>
                        <hr></hr>
                        <div className='d-flex'>
                            <div className="profile-picture-container">
                                <p>Profile Picture</p>
                                <div className="image">
                                    <img src={file ? file : blankProfile} width={'128px'} height={'128px'} />
                                    <div>
                                        <input onChange={(e) => {
                                            onFileInputChange(e)
                                        }} className='uploader' type="file" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-container w-100">
                                <Form
                                    id="profileForm"
                                    form={profileForm}
                                    name="profileForm"
                                    scrollToFirstError
                                    labelCol={{ span: 5 }}
                                    wrapperCol={{ span: 24 }}
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    initialFormData={initialFormData}
                                >
                                    <Form.Item
                                        name="firstName"
                                        rules={[
                                            { required: true, message: 'Please enter first name' },
                                        ]}
                                    >
                                        <Input placeholder='First Name' prefix={<UserOutlined className="site-form-item-icon" />} />
                                    </Form.Item>
                                    <Form.Item
                                        name="lastName"
                                        rules={[
                                            { required: true, message: 'Please enter last name' },
                                        ]}
                                    >
                                        <Input placeholder='Last Name' prefix={<UserOutlined className="site-form-item-icon" />} />
                                    </Form.Item>
                                    <Form.Item
                                        name="email"
                                        rules={[
                                            { required: true, message: 'Please enter email', type: 'email', },
                                        ]}
                                    >
                                        <Input placeholder='Email' prefix={<MailOutlined className="site-form-item-icon" />} />

                                    </Form.Item>
                                    <Form.Item
                                        name="phone"
                                        rules={[
                                            { required: true, message: 'Please enter phone number' },
                                            { max: 10, min: 10, message: 'Please enter 10 digit mobile number' },
                                        ]}
                                        validateTrigger={'onBlur'}
                                    >
                                        <Input placeholder='Phone' type='number' prefix={<PhoneOutlined className="site-form-item-icon" />} />

                                    </Form.Item>
                                    <Form.Item wrapperCol={{ span: 24 }}>
                                        <Button
                                            className="bg-color width-150"
                                            type="primary"
                                            htmlType="submit"
                                        >
                                            Submit
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xl update-password-container">
                    <h5>Update Password</h5>
                    <hr></hr>
                    <div className="form-container w-100">
                        <Form
                            id="updatePasswordForm"
                            form={updatePasswordForm}
                            name="updatePasswordForm"
                            scrollToFirstError
                            labelCol={{ span: 5 }}
                            wrapperCol={{ span: 24 }}
                            onFinish={onFinishPassword}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                        >
                            <Form.Item
                                name="oldpassword"
                                rules={[
                                    { required: true, message: 'Please enter previous password' },
                                ]}
                            >
                                <Input placeholder='Previous Password' type='password' prefix={<LockOutlined className="site-form-item-icon" />} />
                            </Form.Item>
                            <Form.Item
                                name="newpassword"
                                rules={[{ required: true, message: 'Please add a password' },
                                { min: 8, message: 'Password must have a minimum length of 8' },
                                {
                                    pattern: new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$'),
                                    message: 'Password must contain at least one lowercase letter, uppercase letter, number, and special character'
                                }]}
                                validateTrigger={'onBlur'}
                            >
                                <Input placeholder='New Password' type='password' prefix={<LockOutlined className="site-form-item-icon" />} />
                            </Form.Item>
                            <Form.Item
                                name="confirmpassword"
                                rules={[
                                    { required: true, message: 'Please confirm password', type: 'password', },
                                ]}
                            >
                                <Input placeholder='Confirm Password'  type='password' prefix={<LockOutlined className="site-form-item-icon" />} />

                            </Form.Item>
                            <Form.Item wrapperCol={{ span: 24 }}>
                                <Button
                                    className="bg-color width-150"
                                    type="primary"
                                    htmlType="submit"
                                >
                                    Update
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;