import React, { useState } from 'react';
import { Table, Button, Space } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined, CaretDownOutlined, CaretUpOutlined, SwapOutlined } from '@ant-design/icons';
import AddResults from './AddExitPolls';
import { deleteExitPolls, deleteMediaSource } from '../../AadhanApi';


const ListMediaTableResults = ({dataSource,getFetchData,type,data,setType}) => {
    const token = localStorage.getItem('ACCESS_TOKEN')
    const permission = localStorage.getItem('USER_PERMISSIONS')
    const resultPermission = JSON.parse(permission)?.Election?.Results;
  const handleDelete = async(key) => {
    await deleteExitPolls(key,token)
    getFetchData()
  };
  const handleTypeChange =()=>{
if(type==='loksabha_elections'){
    setType('assembly_elections')
}
else{
    setType('loksabha_elections')
}
  }

  const columns = [
    {
      title: 'Media Source',
      dataIndex: 'mediaSource',
      key: 'mediaSource',
      width:200
    },
    {
      title: <div >{type} <span onClick={()=>handleTypeChange()} style={{margin:"0 1rem",cursor:"pointer"}}><SwapOutlined /></span></div>,
      dataIndex: 'type',
      key: 'type',
      width:230
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      width:200
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      width:200
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => (
        <Space size="middle">
          <Button type='primary'><AddResults getFetchData={getFetchData} data={data} type={type} source={record} title={'edit'} /></Button>
          <Button icon={<DeleteOutlined />} onClick={() => handleDelete(record.poll_id)}>Delete</Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
     <Table dataSource={dataSource} columns={columns} />
    </div>
  );
};

export default ListMediaTableResults;
